.listQuestion {
  margin-top: 30px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  width: 100%;
  padding: 20px;

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 5px;
  }

  .typeQuestion {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin: 15px 15px 15px 0px;
  }

  .titleLeave {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #fea628;
    margin-bottom: 0px;
  }

  .groupCheckbox {
    :global {
      .MuiFormControlLabel-root {
        margin-bottom: 0px;
      }
      .MuiTypography-root {
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        color: #000000;
        word-break: break-all;
      }
      .MuiCheckbox-root {
        // padding: 0px;
        // padding-right: 10px;
        &.Mui-checked {
          color: #fea628;
        }
      }
      .MuiFormHelperText-root {
        margin-left: 30px;
        margin-top: -5px;
        color: #8d8d8d;
        font-size: 12px;
      }
    }
  }
}

.iconLoading {
  font-size: 14px;
  color: #fea628;
}
