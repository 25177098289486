@import 'styles/variable.scss';
.manager-test {
  .table-list {
    .status {
      color: #222222;
    }
    .stt-table {
      color: #000000;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
    .cell-table-title {
      color: #223354;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
    .cell-table {
      color: #223354;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .select-skill-test {
    .Mui-disabled {
      opacity: 0.5;
    }
  }
  .table-border-show {
    .info {
      span {
        color: #333333;
      }
    }
  }
  .divider {
    border-bottom: 1px solid #e7e7e7;
  }
  .h-100vh {
    height: 100vh;
  }
  .z-5 {
    z-index: 5;
  }
  .pt-10 {
    padding-top: 10px;
  }
  .pb-22 {
    padding-bottom: 22px;
  }
  .pb-8 {
    padding-bottom: 8px;
  }
  .mr-12 {
    margin-right: 12px;
  }
  .pt-23 {
    padding-top: 23px;
  }
  .pt-30 {
    padding-top: 30px !important;
  }
  .pb-10 {
    padding-bottom: 10px;
  }
  .fs-20 {
    font-size: 20px;
  }
  .mt-25 {
    margin-top: 25px;
  }
  .mr-25 {
    margin-right: 25px;
  }
  .edit-delete-button {
    border: 2px solid #e7e7e7;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 15px;
  }
  .box-more-quetion {
    border: 1px solid #e7e7e7;
  }
  .error-input {
    position: absolute;
  }

  .btn-delete-question:before {
    content: '';
    border-top: 1px solid #e7e7e7;
    position: absolute;
    top: 0;
    right: 17px;
    width: 100%;
    height: 1px;
  }
  .reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 22px 32px;
    margin-top: 10px;
    height: 36px;
    background: $color-navActive;
    border-radius: 5px;
    color: $color-white;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    .img-reset {
      margin: '0 10px 0 0';
    }

    &:hover {
      background: $color-navActive;
      color: $color-white;
    }

    &:active {
      background: $color-blue !important;
      color: $color-white !important;
    }
  }
  .Mui-checked {
    color: #fea628 !important;
    & ~ .MuiSwitch-track {
      background: #ffdba9 !important;
    }
  }
  .MuiSwitch-track {
    background: rgba(0, 0, 0, 0.38) !important;
  }
  // .switch-question-from-ware-house{

  // }
  .list-test {
    ul {
      list-style-type: none;
    }
    .content-l {
      width: 100%;
      padding-right: 30px;
    }
    .content-r {
    }
    .item-test {
      &:not(.item-test:first-child) {
        padding-top: 20px;
      }
    }
    .item-question {
      margin-top: 10px;
    }

    .img-question {
      max-width: 350px;
      max-height: 350px;
      overflow: auto;
      &:hover {
        cursor: pointer;
      }
      img {
        object-fit: cover;
        width: 100%;
      }
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #c4c4c4;
      }
    }
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    text-transform: uppercase;
    color: #333333;
  }
  .flex-button {
    display: flex;
  }

  .btn-cancel-manage-test {
    padding: 0.375rem 0.75rem;
  }
}
.switch-question-from-ware-house {
  .MuiPaper-root {
    max-width: 500px;
  }
  .modal-body {
    padding: 1rem;
    padding-bottom: 0;
  }
  .question-other-title {
    margin-bottom: 5px;
    margin-top: 30px;
  }
  .modal-footer {
    border: none;
    padding: 0px 12px 34px 12px;
    background-color: #fff;
  }
  .mb-14 {
    margin-bottom: 14px;
  }
  .mh-36 {
    min-height: 36px;
  }
  .pl-15 {
    padding-left: 15px;
  }
  .z-9 {
    z-index: 9;
  }
  .checked {
    img {
      width: 17px;
    }
  }
  .level {
    span {
      color: #fea628;
    }
  }
  .text-question-test {
    word-break: break-word;
  }
  .question-item {
    .active {
      position: absolute;
      content: '';
      width: calc(100% + 32px);
      height: 100%;
      background: #fea628;
      z-index: -1;
      opacity: 0.4;
      display: block;
      left: -16px;
    }
  }
  .modal-header {
    background: #222433;
    color: #fff;
  }

  .list-question-from-ware-house {
    max-height: 65vh;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #c4c4c4;
    }
    .list-answer {
      max-height: 190px;
      overflow: auto;
      width: 100%;
      margin-right: 8px;
    }
  }
}

.manage-test-justify {
  .react-confirm-alert-button-group {
    justify-content: space-between;
  }
}

.confirm-saveManager-test {
  h1 {
    padding-left: 15px;
    padding-right: 20px;
  }
}
