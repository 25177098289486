@import 'styles/variable.scss';

.managerKpi {
  :global {
    .info-menu-right-no-image {
      padding: 12px !important;
    }
    .MuiTable-root {
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
  .headerContainer {
    padding-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headTitle {
      display: flex;
      .title {
        white-space: nowrap;
        color: #333333;
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 18px;
        margin-right: 30px;
        text-transform: uppercase;
        margin-bottom: 0px;
        margin-top: -4px;
      }
      .schedule {
        flex-basis: 80%;
        display: flex;
        flex-direction: column;
        gap: 15px;

        &__navigator {
          display: flex;
          flex-direction: row;
          color: #223354;
          font-size: 14px;
          line-height: 22px;

          .previous,
          .next {
            width: 25px;
            text-align: center;
            border: none;
            background-color: inherit;
          }

          .currentDate {
            margin: 0 30px;
          }
        }
      }
    }
    .totalKPIContainer {
      background: #ffdba9;
      border-radius: 5px;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      width: 204px;

      .textTotal {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #333333;
      }
    }
  }

  .filter {
    padding: 0px;
    .buttonCreate {
      margin-bottom: 10px;
    }

    :global {
      .button-add-menu-right {
        height: 38px;
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .info-menu-right-no-image {
        padding-top: 0px;
        padding-bottom: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
      }
    }
  }

  .searchHr {
    background: #ffffff;
    border-radius: 5px;
    margin: 11px 0px;
    input {
      &::placeholder {
        text-transform: uppercase;
        opacity: 0.28;
      }
    }
    :global {
      .MuiInputBase-input {
        color: rgba(0, 0, 0, 0.38);
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        padding: 8.5px 10px 8.5px 0px !important;
      }
    }
  }
}

.fullKPIAlert {
  width: 284px !important;
}

.status {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  &__processing {
    color: #0057ff !important;
  }
  &__completed {
    color: #2ecc71 !important;
  }
  &__uncompleted {
    color: #e74c3c !important;
  }
  &__pending {
    color: #333333 !important;
  }
}

.modalCreateEditKPI {
  max-width: 450px;
  .titleListHR {
    font-weight: 500;
    font-size: 16px;
    color: #223354;
  }
  .searchHr {
    :global {
      .MuiInputBase-input {
        margin-left: -25px;
      }
    }
  }
  .listUser {
    max-height: 280px;
    overflow-y: scroll;
    margin-right: -1.5rem;
    padding-right: 1.5rem;
    margin-left: -1.5rem;
    padding-left: 1.5rem;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
      border-radius: 30px;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
      border-radius: 30px;
    }
    .boxItem {
      border-top: 1px solid #e7e7e7;
      padding-top: 7px;
      padding-left: 5px;
      display: flex;
      :global {
        .MuiAvatar-root {
          margin-right: 10px;
        }
      }

      &:hover {
        cursor: pointer;
      }

      &.isActive {
        background-color: rgba(254, 166, 40, 0.2);
        margin: 0px -24px;
        padding: 7px 24px 0px 29px;
      }
    }
    .info {
      p {
        margin-bottom: 5px;
      }
      .name {
        font-weight: 500;
        font-size: 14px;
        color: #223354;
      }
      .emailPosition {
        font-weight: 400;
        font-size: 12px;
        color: #8d8d8d;
      }
    }
  }
}

.modalDetailKPI {
  :global {
    .MuiPaper-root {
      max-width: none;
      width: 768px;
    }
    .modal-body {
      padding: 20px 15px 30px 20px;
    }
    .MuiTabs-indicator {
      background-color: #fea628 !important;
      top: 40px;
    }
    .Mui-selected {
      color: #223354 !important;
    }
    .MuiTabPanel-root {
      padding: 0px;
      width: 100%;
    }
    .MuiTab-root {
      font-weight: 500;
      font-size: 17px;
      line-height: 19px;
      margin-bottom: 10px;
      color: #8d8d8d;
      min-width: 200px;
    }
    .MuiTableCell-head {
      font-weight: 500;
      color: $color-black;
      background-color: $color-headTable !important;
    }
    td.status-red {
      color: $color-red !important;
    }
    td.status-blue {
      color: $color-blue-download !important;
    }
    td.status-green {
      color: $color-status-blue !important;
    }
  }
  .headerInfo {
    display: flex;
    align-items: center;
    .titleGroup {
      display: grid;
      .titleDetail {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
        margin-bottom: 10px;
      }
      .creatorName {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #8d8d8d;
      }
    }
    .buttonGroup {
      display: flex;
      justify-content: flex-end;
      .btn {
        padding: 6px 28px;
        color: white;
        border-radius: 5px;
        box-shadow: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-transform: none;
        margin-left: 10px;
        &__delete {
          background: #e74c3c;
        }
        &__edit {
          background: #fea628;
        }
      }
    }
  }
  .mainInfo {
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #223354;
    }
    .rowInfo {
      display: flex;
      .titleRow {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #5a6882;
        width: 200px;
      }
      .contentRow {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #223354;
      }
    }
  }
  .tableCandidate {
    border-top: 1px solid #f1f1f1;
    position: relative;
    .tableContainer {
      max-height: 370px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 4px;
        margin-right: -10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 30px;
        background: #c4c4c4;
      }
      .levelCell {
        color: #333333;
      }
    }
    .dateFilterGroup {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 10px;
      right: 5px;
      :global {
        .MuiTextField-root label {
          color: #bdbdbd;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
        }
        .w-140 {
          width: 140px;
        }
      }
    }
  }
}

.modalEditKPI {
  :global {
    .MuiPaper-root {
      min-width: 0 !important;
      width: 468px;
    }
  }
  .infoHR {
    display: flex;
    align-items: center;
    .avatar {
      margin-right: 10px;
      margin-top: -5px;
      width: 35px;
      height: 35px;
    }
    .name {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #223354;
      margin-bottom: 5px;
      display: block;
    }
    .emailAndPosition {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #b8b8b8;
      display: block;
    }
  }
}

.modalExportKPI {
  :global {
    .modal-title-head {
      color: #f9fbfc;
    }
    .MuiPaper-root {
      min-width: 0 !important;
      width: 468px;
    }
    .MuiRadio-root {
      color: #e7e7e7;
    }
    .MuiRadio-root.Mui-checked {
      color: #fea628;
    }
    .MuiFormControlLabel-label {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
    }
  }
  .titleReport {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #223354;
  }
  .radioGroup {
    width: 100%;
  }
  .datePickerGroup {
    margin-bottom: 16px;
  }
  .searchNameHr {
    margin-bottom: 15px;
    :global {
      .MuiInputBase-input {
        margin-left: -25px;
      }
    }
  }
  .listHr {
    max-height: 280px;
    overflow-y: scroll;
    margin: 10px -1.5rem 30px -1.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
      border-radius: 30px;
    }
    .rowItem {
      border-bottom: 1px solid #e7e7e7;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 56px;
      &:first-child {
        border-top: 1px solid #e7e7e7;
      }
      .avatar {
        margin-right: 15px;
        width: 35px;
        height: 35px;
      }
      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #223354;
        display: block;
        margin-bottom: 5px;
      }
      .emailAndPosition {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #b8b8b8;
      }
    }
    .isPickedRow {
      background-color: rgba(254, 166, 40, 0.2);
      margin-left: -1.5rem;
      padding-left: 1.5rem;
      margin-right: -1.5rem;
      padding-right: 1.5rem;
    }
  }
}

.loadingIcon {
  margin-right: 20px;
  height: 28px;
}
.btnConfirm {
  border: none !important;
  line-height: 16px !important;
  background-color: white !important;
  text-transform: uppercase;
  &:hover {
    background-color: #f7fafc !important;
    border: 1px solid #f7fafc;
    box-shadow: 0 7px 14px #32325d1a, 0 3px 6px #00000014;
  }
  &:focus,
  &:active {
    color: #212529;
    background-color: #d2e3ee;
    border-color: #f7fafc;
    border: 1px solid #d2e3ee;
  }
  &__cancel {
    color: #8d8d8d !important;
    font-weight: 400 !important;
    &:hover {
      color: #8d8d8d !important;
    }
  }
  &__ok {
    color: #fea628 !important;
    padding: 0.375rem 0.75rem !important;
    &:hover {
      color: #fea628 !important;
    }
  }
}
