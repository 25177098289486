.container {
  position: relative;
  opacity: 0.5;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);

  .disableLayer {
    cursor: not-allowed;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}
