@import 'styles/variable.scss';

.inputSearchBox {
  background: white;
}
.buttonAddSourceCV {
  padding: 7px 7px !important;
  background-color: #fea628 !important;
  color: white !important;
  border-radius: 5px !important;
  border: 1px solid #fea628 !important;
  height: 36px;
  width: 100%;
  img {
    width: 18px;
    height: 18px;
  }
}

.headerTitle {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #333333;
}

.flexIcon {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.loadingIcon {
  margin-right: 20px;
  height: 28px;
}
.btnConfirm {
  border: none !important;
  line-height: 16px !important;
  background-color: white !important;
  text-transform: uppercase;
  &:hover {
    background-color: #f7fafc !important;
    border: 1px solid #f7fafc;
    box-shadow: 0 7px 14px #32325d1a, 0 3px 6px #00000014;
  }
  &:focus,
  &:active {
    color: #212529;
    background-color: #d2e3ee;
    border-color: #f7fafc;
    border: 1px solid #d2e3ee;
  }
  &__cancel {
    color: #8d8d8d !important;
    font-weight: 400 !important;
    &:hover {
      color: #8d8d8d !important;
    }
  }
  &__ok {
    color: #fea628 !important;
    padding: 0.375rem 0.75rem !important;
    &:hover {
      color: #fea628 !important;
    }
  }
}

.groupIcon {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}
.searchName {
  background: white;
}

.listContainer {
  .colorHeader {
    color: #223354;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  .colorText {
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }

  .sourceCVName {
    width: 50%;
  }
  .sourceCVIndex {
    width: 6%;
  }

  .textSTT {
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }
  :global {
    .mr-15 {
      margin-right: 15px;
    }
    .edit-button {
      svg {
        color: $color-truncate;
        font-size: 26px;
      }

      &:hover {
        transform: scale(1.2);
        cursor: pointer;
      }
    }
  }
}

.modalAddSourceCV {
  :global {
    .modal-title-head {
      color: #f9fbfc;
    }
  }
}
