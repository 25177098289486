.time-line {
  width: 60%;
  margin-top: 30px;
  margin-bottom: 80px;

  .MuiTimelineDot-root {
    background: #526487;
  }
  .MuiTimelineConnector-root {
    width: 0px;
    min-height: 100px;
    border-right: 1px dashed #e7e7e7;
    position: relative;
    .detail-action {
      background-color: rgba($color: #e7e7e7, $alpha: 0.5);
      border: 1px solid #8d8d8d;
      border-radius: 5px;
      width: 385px;
      height: 111%;
      position: absolute;
      top: 0px;
      left: 20px;
      padding-left: 12px;
      display: flex;
      align-items: center;
    }
  }
  .MuiTimelineContent-root {
    color: #223354;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-top: 9px;
  }
}
