@import './../../styles/variable.scss';

.boxMenu {
  * {
    padding: 0;
  }
  @media screen and (max-height: 730px) {
    padding-bottom: 80px;
  }
  position: fixed;
  height: 100%;
  min-width: 244px;
  margin-top: -31px;
  padding-top: 31px;
  overflow-y: scroll;
  .ulMenu {
    flex-direction: column !important;
    width: -webkit-fill-available;
    .activeMasterData {
      border-left: 3px solid;
      background: $bgMenuActive;
      color: $color-menu-active !important;
      span {
        color: $color-menu-active !important;
      }
    }
    li {
      height: 56px;
      width: -webkit-fill-available;
      display: flex;
      align-items: center;
      .iconMenu {
        margin: -2px 0 0 0;
      }
      .iconMenuChilrent {
        margin: -2px 0 0 20px;
      }
      .linkMenu {
        color: $color-menu;
        .itemButtonMenu {
          padding-left: 20px;
          padding-right: 4px;
        }
      }
      .active {
        color: $color-menu-active !important;
        :global {
          .MuiListItemButton-root {
            border-left: 3px solid;
            background: $bgMenuActive;
          }
        }
        span {
          color: $color-menu-active !important;
        }
      }
      .activeMenuChil {
        color: $color-menu-active !important;
        :global {
          .MuiListItemButton-root {
            background: $bgMenuActive;
          }
        }
        span {
          color: $color-menu-active !important;
        }
      }
    }
    .masterDataButton {
      padding-left: 0px;
    }
    .dropDownMenu {
      .iconMenuDropdown {
        min-width: unset;
        img {
          margin: -2px 8px 0 16px;
        }
      }
      a {
        width: -webkit-fill-available;
        :global {
          .MuiListItemIcon-root {
            min-width: unset;
            margin-right: 8px;
          }
        }
        .iconMenuChil {
          margin-left: 4px;
        }
      }
      .menuDropdown {
        span {
          color: $color-menu;
          font-weight: 500;
          font-size: 14px;
          line-height: 32px;
        }
      }
      svg {
        color: $color-dropdown;
      }
    }
    .customSubMenu {
      li:first-child {
        margin-top: -2px;
      }
    }
  }
}
.boxMenu::-webkit-scrollbar {
  width: 4px;
}
.boxMenu.thin::-webkit-scrollbar {
  width: 2px;
}
.boxMenu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #fea628;
}
