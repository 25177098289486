.div-content-average {
  justify-content: space-between;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
}
.span-score-candidate-avg {
  color: #2d99ff;
}
.div-ratting-index {
  display: flex;
  align-content: center;
}
.icon-square {
  margin-right: 5px;
}
