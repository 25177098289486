@import 'styles/variable.scss';
.contentCard {
  background: #f3f7fb;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  border-radius: 4px;
  min-width: 220px;
  padding: 15px;
  z-index: 10;

  .titleCard {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #223354;
  }
  .list {
    .infoRow {
      display: flex;
      align-items: center;
      .avatar {
        width: 17px;
        height: 17px;
      }
      .name {
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        color: #333333;
        margin-left: 5px;
        min-width: fit-content;
      }
      :global {
        .field-key {
          font-weight: 500;
          font-size: 12px;
          line-height: 22px;
          color: #333333;
          margin-left: 3px;
          min-width: fit-content;
        }
        span.status-red {
          color: $color-red !important;
        }
        span.status-blue {
          color: $color-blue1 !important;
        }
        span.status-green {
          color: $color-status-blue !important;
        }
      }
    }
  }
}
