@import "../../styles/variable.scss";
.autoCompleteCustom {
  position: relative;
  height: 60px;
  .form-input {
    border: 1px solid $color-border-form-input;
    border-radius: 4px;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    transition: 0.25s ease;
    &:hover {
      border: 1px solid $color-green;
    }
  }

  .form-input:focus,
  .focus-visible:focus:not(:focus-visible) {
    outline: 0;
  }
  .form-field {
    position: relative;
    &:hover {
      label {
        color: $color-green;
      }
    }
  }
  .form-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    user-select: none;
    color: #999;
    pointer-events: none;
    transition: 0.25s ease;
  }
  .form-input:not(:placeholder-shown) + .form-label,
  .form-input:focus + .form-label {
    top: 0;
    left: 11px;
    padding: 0 10px;
    display: inline-block;
    background-color: $color-white;
    font-size: 12px;
  }
  .form-input:focus + .form-label {
    color: $color-green;
  }
  .form-input.error {
    border: 1px solid $color-red;
  }
  label.error {
    color: $color-red;
    &:hover {
      color: $color-red !important;
    }
  }
  .form-field.error {
    &:hover {
      label {
        color: $color-red !important;
      }
    }
  }
  ul.suggestions {
    width: 100%;
    max-height: 320px;
    overflow: auto;
    position: absolute;
    background: $color-white;
    z-index: 1000;
    padding: 0;
    border: 1px solid $color-dataCV;
    border-top: unset;
    border-radius: 5px;
    li {
      display: flex;
      list-style-type: none;
      padding: 10px 16px;
      cursor: pointer;
      .avatar {
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
      &:hover {
        text-decoration: none !important;
        background-color: rgba(0, 0, 0, 0.04);
      }
      p {
        padding: 0;
        margin: 0;
        margin-left: 10px;
      }
    }
    li.center {
      justify-content: center;
    }
    .no-options-label {
      font-size: 16px;
      color: #828282;
      font-weight: 400;
    }
  }
}
