@import 'styles/variable.scss';

.detail-candidate {
  .link-back {
    text-decoration: none;
    color: #212529 !important;
  }
  .block {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    p {
      margin: 0;
    }
    span {
      font-weight: 600;
    }
    div {
      height: 110px;
      @media (min-width: 1250px) and (max-width: 1320px) {
        height: 120px;
      }
      @media screen and (max-width: 1250px) {
        height: 150px;
      }
      border-radius: 5px;
    }
    .same-width {
      width: 18%;
      @media screen and (max-width: 1250px) {
        width: 17%;
      }
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 4px;
      }
    }
    .block-total {
      width: 8%;
      @media screen and (max-width: 1250px) {
        width: 12%;
      }
      padding: 12px;
      background: $color-yellow4;
      color: $color-navy;
      p {
        font-weight: 500;
      }
      .title {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 10px;
      }
      .value {
        font-size: 34px;
        line-height: 22px;
      }
    }
    .block-wait {
      background: $color-block-wait;
      padding: 8px;
      p {
        color: $color-blue1;
      }
    }
    .block-success {
      padding: 8px;
      background: $color-block-success;
      color: $color-active;
    }
    .block-refuse {
      padding: 8px;
      background: $color-block-refuse;
      color: $color-red;
    }
  }
  .status-red {
    color: $color-red !important;
    cursor: pointer;
  }
  .status-blue {
    color: $color-blue-download !important;
    cursor: pointer;
  }
  .status-green {
    color: $color-status-blue !important;
    cursor: pointer;
  }
  .table-scroll {
    overflow-x: scroll;
    cursor: pointer;
  }
  .scroll-custom {
    border-radius: 0%;
  }
  .scroll-custom::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }
  .scroll-custom::-webkit-scrollbar {
    width: 5px;
  }
  .scroll-custom::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $color-white;
  }
  .scroll-custom::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-navActive;
  }
  .wrapper-top {
    background-color: $bg-body;
    width: 100%;
    overflow-x: scroll;
    cursor: pointer;
    .top {
      width: 140%;
      margin-top: -20px;
    }
  }
  .wrapper-top {
    margin-top: -10px;

    p {
      font-size: 16px;
    }
  }
  .wrapper-top::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }
  .wrapper-top.thin::-webkit-scrollbar {
    width: 8px;
  }
  .wrapper-top::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $color-white;
  }
  .wrapper-top::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-navActive;
  }
  .header-left {
    float: left;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }

  .input-search-box {
    margin: 15px 0 0 0;
  }

  @media screen and (max-width: 1070px) {
    .button-add-menu-right {
      font-size: 13px;
    }
  }

  .button-add-info-apply {
    width: 155px;
    float: right;
  }

  .approve-btn {
    margin-right: 10px;
    background: $color-status-blue;
    border-radius: 5px;
    padding: 6px 16px;
    gap: 8px;
    border: 1px solid #ffffff;
    margin-bottom: 15px;
    width: 134px;
    height: 36px;

    &:hover {
      background: $color-status-blue;
      border: 1px solid $color-status-blue;
    }
    &.disabled {
      background-color: #2ecc71cc !important;
      border: 1px solid #2ecc71cc !important;
    }
  }

  .not-approve-btn {
    background-color: $color-refuse;
    border: 1px solid #ffffff;
    border-radius: 5px;
    padding: 6px 16px;
    gap: 8px;
    margin-bottom: 15px;
    width: 134px;
    height: 36px;

    &:hover {
      background-color: $color-refuse;
      border: 1px solid $color-refuse;
    }
    &.disabled {
      background-color: #e74c3ccc !important;
      border: 1px solid #e74c3ccc !important;
    }
  }

  .header-back-to-list {
    padding-top: 1px;
    font-size: 20px;
  }

  .header-detail {
    margin-top: 25px;
    margin-bottom: 15px;
    justify-content: space-between;
    position: relative;
  }

  .text-info-main {
    font-weight: 700;
    font-size: 18px;
    color: #223354;
  }

  .profileBoxInfo {
    padding-bottom: 0;
    width: 100%;
    height: auto;
    border: 1px solid $color-borderTable;
    border-radius: 5px;
    color: $color-black;
    padding: 1rem 0 0 0;
    margin-bottom: 20px;
    background-color: white;
    box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
    .rowTitle {
      margin: auto;
      border-bottom: 1px solid $color-grey;
      width: 96%;

      .profileTitle {
        img {
          padding-bottom: 3px;
          margin-right: 7px;
          height: 24px;
          width: auto;
        }
      }
    }
  }

  .cv-text-name {
    font-weight: 700;
    font-size: 16px;
    color: #333333;
  }

  .sub-description-cv {
    color: #575656;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .styleInfoMui {
    padding-left: 16px;
    padding-top: 16px;
    font-size: 14px;
    color: $color-charcoal;

    .col1InfoLeft {
      padding-left: 10px;
      padding-bottom: 15px;
      font-weight: 400;
      float: left;
      width: 35%;
      height: 100%;
      color: #5a6882;
    }
    .col2InfoLeft {
      float: left;
      padding-bottom: 15px;
      font-weight: 400;
      width: 40%;
      height: 100%;
      color: #5a6882;
    }
    .styleBackground {
      background-color: $bg-rowTable;
    }
    :global {
      .MuiGrid-item {
        padding-bottom: 15px;
      }
    }
    @media (max-width: 1200px) {
      .col2InfoLeft {
        padding-left: 25px;
        width: 35%;
      }
      .styleBackground {
        background-color: $bg-white;
      }
      :global {
        .MuiGrid-item:nth-child(even) {
          background-color: $bg-rowTable;
        }
      }
    }
  }

  .box-info-apply-wap {
    flex-wrap: wrap;
  }

  .header-box-info-apply {
    background-color: $color-headTable;
    height: 80;
    border-radius: 5px;
    padding: 12px 10px 12px 15px;
    position: relative;
    .job-name {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-right: 10px;
    }
  }

  .body-box-info-apply {
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 10px;
    background-color: $color-menu;
  }

  .list-apply {
    width: 100%;
  }

  .box-info-apply {
    box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
    margin-bottom: 25px;
    border-radius: 5px;
  }

  .button-detail-apply-info {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 20px;
    padding-right: 20px;
    .a-custom {
      text-decoration: underline;
      font-size: 20px;
      color: #2D99FF !important;
      font-weight: 700;
      cursor: pointer;
    }
  }

  .edit-apply-button {
    padding: 3px;
    border-radius: 100%;
    vertical-align: middle;
    width: 35px;
    height: 35px;
    border: 2px solid #e7e7e7;
    margin-right: -30px;
    background: #f9fbfc;
    text-align: right;
    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }

  .edit-candidate-button {
    padding-right: 2px;
    border-radius: 100%;
    vertical-align: middle;
    width: 35px;
    height: 35px;
    border: 2px solid #e7e7e7;
    margin-right: -30px;
    background: #f9fbfc;
    text-align: right;
    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }

  .color-silver {
    svg {
      font-size: 26px;
      color: $color-silver !important;
    }
  }

  .box-position-button-edit {
    position: relative;
    top: 0;
    right: 0;
  }
  .position-button-edit {
    position: absolute;
    right: 47px;
    top: 20px;
  }

  .text-info-custom {
    font-weight: 700;
    font-size: 14px;
    color: #223354;
    word-break: break-word;
    padding-left: 10px;
  }

  .break-text {
    word-break: break-word;
    padding-right: 10px;
  }

  .info-content {
    margin-right: 10px;
  }

  .info-title {
    font-weight: 400;
    font-size: 14px;
    color: #5a6882;
  }
  .detail-candidate-text-link {
    word-break: break-all;
    padding-left: 10px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .position-button-edit-recruit {
    position: absolute;
    right: 40px;
    top: -4px;
  }

  .fixParent {
    position: relative;
    display: flex;

    .pdfThumb {
      margin-right: 1px !important;
      width: 80px;
      height: 80px;
    }

    .fix {
      position: absolute;
      width: 16px;
      background-color: white;
      height: 75px;
      right: 0;
      top: -5px;
    }
    .fixBottom {
      position: absolute;
      background-color: white;
      height: 15px;
      right: 0;
      left: -1px;
      bottom: -1px;
    }
  }

  .contentCV {
    margin: 5px 17px 0 2px;
    align-items: center;

    .flex-end {
      display: flex;
      justify-content: flex-end;
    }

    .displayContentCV {
      display: flex;

      embed {
        border-radius: 5px;
        margin-right: 10px;
        cursor: pointer;
      }

      .profileContentInfo {
        line-height: 28px;
      }

      div.profileContentInfo:first-child {
        font-size: 1rem;
        font-weight: 700;
      }

      div.profileContentInfo:not(:first-child) {
        color: $color-dataCV;
        font-weight: 400px;
      }

      img:first-child {
        border-radius: 6px;
        width: 82px;
        height: 82px;
        margin-right: 15px;
      }

      .editDeleteCV:first-child {
        img {
          width: 22px;
          height: 26px;
        }
      }

      .editDeleteCV:nth-child(2) {
        margin-right: 20px;

        img {
          width: 30px;
          height: 26px;
        }
      }

      .editDeleteCV:hover {
        transform: scale(1.2);
        cursor: pointer;
      }

      .displayButtonCV {
        display: flex;
      }

      input {
        display: none;
      }
    }
  }

  .icon-download {
    height: 22px;
    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }

  .imgTitle {
    margin-left: 10px;
  }

  .fix-padding-bottom {
    padding-bottom: 10px;
  }
}
