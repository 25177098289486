.popupBtn {
  text-transform: uppercase;
  font-size: 14px !important;
  background-color: transparent !important;
  border: transparent !important;
  &__cancel {
    color: #8d8d8d !important;
    font-weight: 400 !important;
  }
  &__ok {
    color: #fea628 !important;
    font-weight: 500 !important;
  }
}

.popupApproveCandidate {
  :global {
    .MuiDialog-paper {
      max-width: 524px;
    }
  }
}

.groupButtonCV {
  display: flex;
  .previewButton {
    margin-left: 12px;
  }
}

.loadingIcon {
  margin-right: 40px;
  height: 28px;
}

.tooltipJobName {
  color: #333333;
  background-color: #f3f7fb;
  max-width: 400px;
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  border-radius: 4px;
}

.itemContainer {
  display: flex;
  align-items: center;
  .titleItem {
    color: #5a6882;
    font-weight: 400;
    font-size: 14px;
  }
  .contentItem {
    color: #223354;
    font-weight: 700;
    font-size: 14px;
  }
}

.backgroundHightLight {
  background: #f9f9f9;
  padding-left: 20px;
  padding-right: 20px;
  height: 65px;
  display: flex;
  align-items: center;
}
.rowContainer {
  padding-left: 20px;
  padding-right: 20px;
  min-height: 65px;
  display: flex;
  align-items: center;
}
.email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #223354;
  font-weight: 700;
  font-size: 14px;
}
