.popupComponent {
  :global {
    .MuiDialogContent-root {
      min-width: 300px;
    }
  }
  p {
    color: #333333;
    font-size: 14px;
  }
}
