.boxStatusHeader {
  a {
    color: #223354;
    &:hover {
      text-decoration: none;
      color: #223354;
    }
  }
  .statusLoading {
    margin-top: 8px;
    margin-bottom: 10px;
  }
}

.boxActive {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.groupButtonCV {
  display: flex;
  .previewButton {
    margin-left: 12px;
  }
}

.containerBaseInfo {
  padding: 20px 15px;
  margin: 0px !important;
  width: 100% !important;
  background: #ffffff;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  border-radius: 3px;
  margin-bottom: 20px !important;
  .buttonEditInformation {
    border-radius: 50%;
    border-style: none;
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }
  .containerInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .titleHeader {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #223354;
    text-transform: uppercase;
  }

  .titleItem {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #5a6882;
  }

  .contentItem {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #223354;
    word-break: break-word;
    text-align: end;
    padding-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .contentItemOverflow {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #223354;
    text-align: end;
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .renderCvContainer {
    border-top: 1px solid #e7e7e7;
    :global {
      .contentCV {
        margin: 0;
        margin-top: 15px;
        max-width: 100%;
        .iconCV-container {
          max-width: 80px;
          display: inline-block;
          top: -15px;
        }
        .infoCV-container {
          max-width: calc(100% - 80px);
          display: inline-block;
          .cv-text-name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
