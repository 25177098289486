.btnCancel,
.btnOk {
  margin-bottom: 5px;
  padding: 6px 8px;
  font-size: 14px;
  &:hover {
    color: #fea628;
    box-shadow: 0 7px 14px #32325d1a, 0 3px 6px #00000014;
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    background-color: #f7fafc;
    border-color: #f7fafc;
    border: 1px solid #f7fafc;
  }
  &:focus,
  &:active {
    color: #212529;
    background-color: #d2e3ee;
    border-color: #f7fafc;
    border: 1px solid #d2e3ee;
  }
}

.btnCancel {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #8d8d8d;
  background: #ffffff;
  border: none;
}
.btnOk {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #fea628;
  background: #ffffff;
  border: none;
}
