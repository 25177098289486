.containerRatting {
  :global {
    .div-content-average {
      justify-content: space-between;
      padding: 5px;
      padding-right: 10px;
      padding-left: 10px;
    }
    .span-score-candidate-avg {
      color: #2d99ff;
    }
    .div-ratting-index {
      display: block !important;
      span {
        margin-right: 10px;
      }
    }
    .icon-square {
      margin-right: 5px;
    }
  }
  padding: 20px 15px;
  background: #ffffff;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  margin-bottom: 10px;
  border-radius: 3px;

  .avgScore {
    font-size: 18px;
    color: rgba(34, 51, 84, 1);
    font-weight: 700;
    font-family: 'Roboto';
  }
  .contentScore {
    margin-bottom: 10px;
    justify-content: space-between;
    border-top: 1px solid #e9ebee;
    height: 40px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .ScoreIndex {
    display: flex;
    align-items: center;
  }

  .titleHeader {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #223354;
    text-transform: uppercase;
  }
  .avgScoreRating {
    color: #2d99ff;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
  .avgScoreStand {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #fea628;
  }
}
