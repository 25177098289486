.container {
  border-radius: 5px 5px 0px 0px !important;
  border: 1px solid #e7e7e7;
  margin-top: 30px;
  max-height: 450px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
    margin-right: -10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: #c4c4c4;
  }
  .rowHeader {
    .cellHeader {
      height: 85px;
      color: #000000;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      padding: 0px 15px;
      background-color: #e9ebee !important;
    }
  }
  .rowBody {
    .cellBody {
      color: #333333;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      width: 9% !important;
    }
    .cellPositionName {
      color: #333333;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
  }
  .indexEvenRowBody {
    background: #f9f9f9;
  }
}
.totalRow {
  border: 1px solid #e7e7e7;
  border-radius: 0px 0px 4px 4px;
  background: #e9ebee;
  display: flex;
  .totalCellTitle {
    color: #223354;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    // width: 19% !important;
    padding: 9px 16px;
  }
  .totalCell {
    color: #223354;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    width: 12% !important;
    padding: 9px 16px;
  }
}
