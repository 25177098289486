.modal-book-interview {
  .button-cancel {
    width: 100%;
  }
  .button-submit {
    width: 100%;
  }

  .MuiGrid-root > .MuiGrid-item {
    padding-left: 21px;
    padding-top: 10px;
  }

  .MuiDialog-paper {
    max-width: 500px;
  }

  .MuiRadio-root {
    color: #e7e7e7;
  }
  .MuiRadio-root.Mui-checked {
    color: #fea628;
  }

  .MuiAutocomplete-popupIndicator .MuiSvgIcon-root {
    font-size: 2.25rem;
  }

  .MuiAutocomplete-endAdornment {
    top: 8px;
  }
  .MuiInputLabel-root {
    color: rgba(0, 0, 0, 0.38) !important;
  }
  .MuiFormControlLabel-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .interviewer {
    .MuiSvgIcon-root {
      display: none;
    }
    .MuiChip-deleteIcon {
      display: inline-block;
    }
  }
  .date-label {
    .MuiInputLabel-root {
      font-size: 14px;
    }
  }
}
