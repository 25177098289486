@import './../styles/variable.scss';

.tableCommon {
  :global {
    table {
      thead {
        th:first-child {
          border-top-left-radius: 5px;
        }
        th:last-child {
          border-top-right-radius: 5px;
        }
      }
      th {
        color: $color-black;
      }
      td {
        border-bottom: none;
      }
      .id-standard {
        color: $color-black !important;
      }
      tr.headTable {
        font-weight: 500;
        color: $color-black;
        background-color: $color-headTable !important;
      }
      tr:nth-child(odd) {
        background-color: $bg-white;
      }
      tr:nth-child(even) {
        background-color: $bg-tableStripped;
      }
    }
  }
  .sttCell {
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .normalCell {
    color: #223354;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .rowTable {
    cursor: pointer;
  }
}
