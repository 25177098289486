.divButtonReviewPrompt {
  position: absolute;
  top: 25%;
  right: 25px;
}
.divButtonReviewPrompt {
  button {
    background: #0c8aff;
    border: none !important;
    height: 34px;
    border-radius: 5px;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    border: 1px solid;
    font-size: 11px;
    padding: 6px 30px;
  }
  :global {
    .Mui-disabled {
      background-color: #0c8aff80 !important;
      border: none !important;
      color: white !important;
    }
  }
  .iconLoading {
    font-size: 16px;
    margin-left: 10px;
  }
}
