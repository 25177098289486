.container {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
  height: 100%;
  position: relative;
  .icon {
    margin-right: 10px;
  }
  .title {
    color: #223354;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
}
