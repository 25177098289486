@import 'styles/variable.scss';

.box-table-referral-bonus-scroll {
  .table-scroll {
    overflow-x: scroll;
    cursor: pointer;
  }
  .scroll-custom {
    border-radius: 0%;
  }
  .scroll-custom::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }
  .scroll-custom::-webkit-scrollbar {
    width: 5px;
  }
  .scroll-custom::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $color-white;
  }
  .scroll-custom::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-navActive;
  }
  .wrapper-top {
    background-color: $bg-body;
    width: 101%;
    overflow-x: scroll;
    height: 5px;
    cursor: pointer;
  }
  .wrapper-top::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }
  .wrapper-top.thin::-webkit-scrollbar {
    width: 8px;
  }
  .wrapper-top::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $color-white;
  }
  .wrapper-top::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-navActive;
  }
  table {
    tr.headTable {
      font-weight: 500;
      color: $color-black;
      background-color: $color-headTable !important;
    }
    th {
      color: $color-black;
    }
    td {
      p {
        margin: 0;
        font-size: 14px;
      }
    }
    tr:nth-child(odd) {
      background-color: $bg-white;
      td.checkbox-permanent {
        background-color: $bg-white;
      }
    }
    tr:nth-child(even) {
      background-color: $bg-tableStripped;
      td.checkbox-permanent {
        background-color: $bg-tableStripped;
      }
    }
  }

  .text-color-data {
    color: #223354;
  }
  .text-color-STT {
    color: #000000;
  }

  // =====================================
}
.confirm-info-bonus {
  .react-confirm-alert-body {
    width: 300px;
  }
}
