.loadingIcon {
  margin-right: 20px;
  height: 28px;
}
.btnConfirm {
  border: none !important;
  line-height: 16px !important;
  background-color: white !important;
  text-transform: uppercase;
  &:hover {
    background-color: #f7fafc !important;
    border: 1px solid #f7fafc;
    box-shadow: 0 7px 14px #32325d1a, 0 3px 6px #00000014;
  }
  &:focus,
  &:active {
    color: #212529;
    background-color: #d2e3ee;
    border-color: #f7fafc;
    border: 1px solid #d2e3ee;
  }
  &__cancel {
    color: #8d8d8d !important;
    font-weight: 400 !important;
    &:hover {
      color: #8d8d8d !important;
    }
  }
  &__ok {
    color: #fea628 !important;
    padding: 0.375rem 0.75rem !important;
    &:hover {
      color: #fea628 !important;
    }
  }
}

.groupIcon {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}
