.popupApproveCandidate {
  :global {
    .MuiDialog-paper {
      width: 499px;
    }
  }
}

.popupBtn {
  text-transform: uppercase;
  font-size: 14px !important;
  background-color: transparent !important;
  border: transparent !important;
  &__cancel {
    color: #8d8d8d !important;
    font-weight: 400 !important;
  }
  &__ok {
    color: #fea628 !important;
    font-weight: 500 !important;
  }
  &__notOk {
    color: #333333 !important;
    font-weight: 400 !important;
  }
}

.loadingIcon {
  margin-right: 40px;
  height: 28px;
}