.managerCost {
  padding-bottom: 40px;
  :global {
    .info-menu-right-no-image {
      padding: 12px !important;
    }
  }
  .headTitle {
    width: 100%;
    .title {
      white-space: nowrap;
      color: #333333;
      font-family: 'Roboto';
      font-weight: 700;
      font-size: 18px;
      margin-right: 30px;
      text-transform: uppercase;
    }
    .schedule {
      display: flex;
      flex-direction: column;
      gap: 15px;

      &__navigator {
        display: flex;
        flex-direction: row;
        color: #223354;
        font-size: 14px;
        line-height: 22px;

        .previous,
        .next {
          width: 25px;
          text-align: center;
          border: none;
          background-color: inherit;
        }

        .currentDate {
          margin: 0 30px;
        }
      }
    }
  }

  .buttonMB {
    margin-bottom: 10px;
  }
  .searchHr {
    background: #ffffff;
    border-radius: 5px;
    margin: 11px 0px;
    input {
      &::placeholder {
        text-transform: uppercase;
        opacity: 0.28;
      }
    }

    .MuiInputBase-input {
      color: rgba(0, 0, 0, 0.38);
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      padding: 8.5px 10px 8.5px 0px !important;
    }
  }

  .status {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    &__processing {
      color: #0057ff;
    }
    &__completed {
      color: #2ecc71;
    }
    &__uncompleted {
      color: #0057ff;
    }
    &__pending {
      color: #2ecc71;
    }
  }
}

.modalCreateEditCost {
  max-width: 450px;
  .titleListHR {
    font-weight: 500;
    font-size: 16px;
    color: #223354;
  }
  .listUser {
    max-height: 280px;
    overflow-y: scroll;
    margin-right: -1.5rem;
    padding-right: 1.5rem;
    margin-left: -1.5rem;
    padding-left: 1.5rem;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
      border-radius: 30px;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
      border-radius: 30px;
    }
    .boxItem {
      border-top: 1px solid #e7e7e7;
      padding-top: 7px;
      padding-left: 5px;
      display: flex;
      :global {
        .MuiAvatar-root {
          margin-right: 10px;
        }
      }

      &:hover {
        cursor: pointer;
      }

      &.isActive {
        background-color: rgba(254, 166, 40, 0.2);
        margin: 0px -24px;
        padding: 7px 24px 0px 29px;
      }
    }
    .info {
      p {
        margin-bottom: 5px;
      }
      .name {
        font-weight: 500;
        font-size: 14px;
        color: #223354;
      }
      .emailPosition {
        font-weight: 400;
        font-size: 12px;
        color: #8d8d8d;
      }
    }
  }
}
