@import 'styles/variable';
.management-decentralization {
  .w-92i {
    width: 92px !important;
  }
  .mr-15 {
    margin-right: 15px;
  }
  .padding-btn {
    padding: 0.375rem 0.75rem !important;
  }
  .filter-decentralization {
    padding: 0px;
    .button-add-menu-right {
      height: 36px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .info-menu-right-no-image {
      padding-top: 0px;
      padding-bottom: 0px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
    }
  }
  &.edit-delete-button {
    border: 2px solid #e7e7e7;
  }
  .employee-decentralization-btn {
    width: 35px;
    height: 35px;
    padding: 3px;
    border-radius: 50%;
    border: 1px solid #e7e7e7;
    cursor: pointer;
    svg {
      color: $color-truncate !important;
      font-size: 27px;
    }
    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
    &--edit {
      margin-right: 15px;
    }
  }
  .mt-15 {
    margin-top: 15px;
  }
  .base-breadcrumb {
    li {
      cursor: pointer;
      color: #333333;
    }
  }
  .breadcrumb {
    background: transparent !important;
    li {
      span,
      a {
        color: #222222;
      }
      a {
        text-decoration: underline;
      }
      span {
        margin: 0px 5px;
      }
    }
    li:last-child span {
      display: none;
    }
  }
  .icon {
    background: #e4f5ff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    img {
      margin-bottom: -3px;
      width: 20px;
      height: 20px;
    }
  }
  .menu-right {
    white-space: break-spaces;
  }
  &.create-position {
    .title,
    .sub-title {
      width: 30%;
      color: #333333;

      span {
        font-weight: 400;
      }
    }
    .title.use-sub-menu {
      width: 100%;
      padding-top: 10px;
    }
    .permission .MuiFormControlLabel-label {
      margin-right: 20px;
      font-size: 12px;
    }
    .submenu {
      width: 100%;
      .sub-title {
        padding-left: 60px;
      }
    }
    .permission {
      svg {
        fill: #979797;
      }
      .Mui-checked svg {
        fill: #fea628;
      }
      .MuiFormControlLabel-label {
        color: #8d8d8d;
      }
    }

    .MuiFormControlLabel-labelPlacementEnd {
      margin-bottom: 0px;
    }
  }
  .title-outside {
    margin-bottom: 15px !important;
  }
  .table-content {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
  .edit-role-title {
    margin-top: -20px;
    margin-bottom: 20px !important;
  }
  .tabs {
    margin-top: -16px;
    margin-bottom: 25px;
    button {
      font-weight: 700;
      color: #333333 !important;
      font-size: 18px;
      padding-bottom: 5px;
      margin-right: 70px;
    }
    .MuiTabs-indicator {
      background: #fea628 !important;
    }
  }

  .input-search {
    width: 100%;
  }
  .btn-fnc-edit-role {
    margin-top: 40px;
  }
  .popup-edit-role {
    .divider {
      margin: 10px 0px 27px 0px;
    }
  }
  .edit-delete-button {
    float: none !important;
  }
  .table-list-manager .edit-delete-button {
    border: 2px solid #e7e7e7;
  }
  #alert-dialog-description {
    white-space: pre;
    color: #333333;
  }
  .edit-role-title {
    margin-top: -27px !important;
    margin-bottom: 12px !important;
  }
  .content-component {
    .page-title {
      font-size: 18px;
      color: #333333;
      font-weight: 700;
    }
  }
  .parent-menu .title span {
    font-weight: 500;
  }
  .setting-decentralization-title {
    font-size: 16px;
    color: #333333;
    width: max-content;
  }
  .setting-decentralization-content {
    font-size: 16px;
    color: #223354;
    margin-top: 20px;
  }
  .table-list-manager .id-standard {
    color: #000000;
  }
  .table-list-manager tr td:nth-child(2),
  .table-list-manager tr td:nth-child(3),
  .table-list-manager tr td:nth-child(4) {
    color: #223354;
  }
}

.master-data-create-filter {
  margin-bottom: 100px;
  .infor-on-table {
    display: flex;
    .align-items-center {
      position: absolute;
      right: 20px;
      background: $bg-yellow2;
      border-radius: 5px;
      color: $bg-yellow1;
    }
  }
  .save {
    width: 85px;
    float: right;
  }
  .loading-custom {
    margin-top: 200px !important;
  }
  a {
    text-decoration: none !important;
  }
  margin-top: 20px;
  .title-list-member {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $color-navActive;
  }
  .table-list {
    margin-top: 12px;
    box-shadow: 0px 5px 25px $color-box-shadow;
    color: $color-black;
    background: $color-table;
    box-sizing: border-box !important;
    border-radius: 5px;
    border: 2px solid $color-boder-table;
    max-height: 600px;
    tr.header {
      position: sticky;
      top: 0;
      height: 40px;
      z-index: 1;
    }
    th {
      vertical-align: bottom;
      border-bottom: 2px solid $color-boder-table;
    }
    td {
      padding: 16px;
      border-bottom: none;
    }
    td.error-no-data {
      text-align: center;
      color: $color-red;
    }
    .css-1nr2wod {
      color: $color-switch-base !important;
    }
    .css-1nr2wod.Mui-checked + .MuiSwitch-track {
      background-color: $color-muiswitch-track !important;
    }
  }
  .table-list::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  .table-list.thin::-webkit-scrollbar {
    width: 2px;
  }
  .table-list::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $color-boder-table;
  }
  .table-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $bg-yellow1;
  }
  .table-list::-webkit-scrollbar-track-piece:start {
    margin-top: 40px;
    background: $bg-white;
  }

  .filter {
    background: $color-table;
    border: 1px solid $color-borderTable;
    box-sizing: border-box;
    box-shadow: 0px 5px 25px $color-box-shadow;
    border-radius: 5px;
    padding: 20px;
    .error.images {
      margin-top: 30px;
      text-align: center;
    }
    .field-name {
      margin-top: 55px !important;
      margin-bottom: 1.5rem !important;
    }
    .division {
      margin-bottom: 1.5rem !important;
    }
    h1 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $color-navy;
    }
  }
}
.master-data-create-group-account {
  a {
    text-decoration: none !important;
  }
  .bread-crumbs {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    .page-current {
      color: $color-navy;
      font-weight: 500;
    }
    a {
      color: $color-silver;
    }
  }
  img.radio {
    width: 20px;
    cursor: pointer;
  }
  .filter-custom {
    margin-top: 50px;
    display: flex;
    span {
      cursor: pointer;
      margin-left: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $color-titleExport;
    }
    span.create-filter {
      margin-right: 100px;
    }
  }
}
.master-data-group-access {
  .sidebar {
    padding-right: 10px;
  }
  .table-list {
    margin-bottom: 100px;
    margin-top: 55px;
    box-shadow: 0px 5px 25px $color-box-shadow;
    color: $color-black;
    background: $color-table;
    box-sizing: border-box !important;
    border-radius: 5px;
    border: 2px solid $color-boder-table;
    th {
      vertical-align: bottom;
      border-bottom: 2px solid $color-boder-table;
    }
    td {
      padding: 16px;
      border-bottom: none;
      a {
        text-decoration: none !important;
        color: $color-nav !important;
      }
    }
    td.error-no-data {
      text-align: center;
      color: $color-red;
    }
  }
}

// Pop up
.no-padding-modal-body {
  .modal-body {
    overflow-y: unset;
  }

  .selected-division,
  .selected-position {
    .MuiAutocomplete-endAdornment {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .MuiOutlinedInput-notchedOutline {
      bottom: -5px;
    }
    .MuiInputLabel-formControl {
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .modal-title-head {
    font-size: 16px;
  }
  .name-user {
    span {
      color: #333333;
      font-weight: 500;
    }
  }

  .popup-add-member-custom {
    input::placeholder {
      color: #000000;
    }
  }
  .buttonAssign {
    width: 100%;
    background: #fea628;
    border: none;
    border-radius: 5px;
    padding: 6px 16px;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
}
.popup-add-member-custom {
  .search-employee {
    background: #ffffff;
    border-radius: 5px;
    .MuiInputBase-input {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      margin-left: -24px;
    }
  }
  .list-member-custom {
    min-height: 325px;
    margin-top: 15px;
    .select-all-option {
      color: $color-buttonSubmit;
      &:hover {
        cursor: pointer;
      }
    }

    .not-select-all-option {
      color: $color-grey1;
      &:hover {
        cursor: pointer;
      }
    }

    .disable-checkbox {
      .MuiSvgIcon-root {
        display: none;
      }
    }

    .list-member-checkbox {
      margin: 0 -24px;
      overflow-y: scroll;
      max-height: 300px;
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 30px;
        background-color: #c4c4c4;
      }
      .error {
        text-align: center;
      }
      .MuiListItemButton-root {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 24px;

        .MuiListItemText-root {
          margin: 0;
          border-top: 1px solid $color-border-input;
        }
      }

      .selected-item {
        background-color: $color-active-selected;
      }

      .item-selected {
        &:last-child {
          .MuiListItemText-root {
            border-bottom: 1px solid $color-border-input;
          }
        }
        padding: 0;
        margin: 1px 0;
        height: 100%;
        min-height: 53px;
        display: flex;
        align-items: center;
        label {
          margin: 0;
          width: 100%;
          min-height: 53px;
          flex-direction: row-reverse;
          justify-content: space-between;
        }

        .info-wrapper {
          .avatar {
            margin-right: 10px;
            border-radius: 50%;
            section {
              width: 40px;
              height: 40px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
          }

          .bottom-content {
            font-size: 14px;
            color: $color-silver;
          }
        }
      }
    }
    p.title,
    .info-wrapper .user-name {
      color: #223354;
    }
  }
}

.icon--loading {
  margin-right: 20px;
  height: 28px;
}
.btnConfirm--cancel {
  border: none !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  color: #8d8d8d !important;
  background-color: white !important;
  text-transform: uppercase;
  &:hover {
    color: #8d8d8d !important;
    background-color: #f7fafc !important;
    border: 1px solid #f7fafc;
    box-shadow: 0 7px 14px #32325d1a, 0 3px 6px #00000014;
  }
  &:focus,
  &:active {
    color: #212529;
    background-color: #d2e3ee;
    border-color: #f7fafc;
    border: 1px solid #d2e3ee;
  }
}
.btnConfirm--ok {
  border: none !important;
  line-height: 16px !important;
  color: #fea628 !important;
  background-color: white !important;
  text-transform: uppercase;
  padding: 0.375rem 0.75rem !important;
  &:hover {
    color: #fea628 !important;
    background-color: #f7fafc !important;
    border: 1px solid #f7fafc;
    box-shadow: 0 7px 14px #32325d1a, 0 3px 6px #00000014;
  }
  &:focus,
  &:active {
    color: #212529;
    background-color: #d2e3ee;
    border-color: #f7fafc;
    border: 1px solid #d2e3ee;
  }
}

.dialog-tab-list-manager {
  .title-create-employee-success,
  .title-update-role-success {
    color: #333333;
  }
  .btn-create-employee-success,
  .btn-update-role-success,
  .btn-delete-success {
    text-transform: uppercase;
  }
}
.edit-role-modal .modal-body {
  padding-bottom: 0px;
  padding-top: 15px;
}
.management-decentralization-delete-role .MuiDialog-paper {
  max-width: 285px;
  p {
    font-size: 14px;
  }
}

.alert-text-color {
  color: #333333 !important;
}
