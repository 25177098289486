.bodyDesiredCandidate, .bodyCandidateEvaluation {
  padding: 20px 15px;
  width: 100%;
  height: auto;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  color: black;
  padding: 1rem 0 0 0;
  margin-bottom: 20px;
  background-color: white;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  .titleDesiredCandidate, .titleCandidateEvaluation {
    justify-content: space-between;
    .titleInfoWishes {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #223354;
      padding-left: 15px;
    }
    .buttonSettingDesired {
      border-radius: 50%;
      border-style: none;
      margin-right: 15px;
      width: 25px;
      height: 25px;
      .imgIconUpdateWishes {
        width: 14px;
        height: 13px;
      }
    }
  }
  .bodyContentDesiredCandidate, .bodyContentCandidateEvaluation {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px 10px 15px;
    .titleDesiredCandidate, .titleCandidateEvaluation {
      color: #5a6882;
      font-size: 14px;
      font-weight: 400;
      font-family: Roboto;
      line-height: 16px;
    }
    .contentDesiredCandidate {
      color: #223354;
      line-height: 16px;
      font-size: 14px;
      font-weight: 700;
      font-family: Roboto;
      word-break: break-word;
      text-align: end;
    }
  }
}
