@import './../../styles/variable.scss';

.modalAlert {
  z-index: 9999 !important;

  p {
    width: 240px;
    color: #333333;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .linkDone {
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
    }
  }
  :global {
    .modal-footer {
      padding-top: 0px !important;
      border-top: none;
    }
    .modal-body {
      padding-top: 2rem;
    }
    button {
      color: $color-yellow;
      text-transform: uppercase;
      font-size: 14px;
      border: 1px solid transparent;
      box-shadow: none;
      background: inherit;
      outline: 0;

      &:hover {
        // color: $color-yellow;
        // background-color: $bg-white;
        // border: 1px solid $color-yellow;
        color: #fea628;
        box-shadow: 0 7px 14px #32325d1a, 0 3px 6px #00000014;
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
        background-color: #f7fafc;
        border-color: #f7fafc;
        border: 1px solid #f7fafc;
      }
      &:focus,
      &:active {
        // color: $color-white !important;
        // background-color: $bg-yellow1 !important;
        // border: 1px solid $color-yellow !important;
        // outline: 0;
        color: #212529;
        background-color: #d2e3ee;
        border-color: #f7fafc;
        border: 1px solid #d2e3ee;
      }
    }
  }
}

@media (min-width: 576px) {
  .modalAlert {
    :global {
      .modal-body {
        min-width: 285px;
      }
    }
  }
}
