.container {
  margin-bottom: 30px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
  }
  .number {
    margin-left: 40px;
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    color: #fea628;
  }
}
