@import 'styles/variable.scss';
.manager-job {
  td.status-red {
    color: $color-red !important;
  }
  td.status-blue {
    color: $color-blue1 !important;
  }
  td.status-green {
    color: $color-status-blue !important;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .wait {
    color: $color-silver !important;
  }
  .processing {
    color: $color-blue1 !important;
  }
  .lateDeadline {
    color: $bg-yellow1 !important;
  }
  .cancel {
    color: $color-cancel !important;
  }
  .completed {
    color: $color-active !important;
  }
  .w-113 {
    width: 113px;
  }
  .w-120 {
    width: 120px;
  }
  .w-103 {
    width: 103px;
  }
  .w-90 {
    width: 90px;
  }
  .w-88 {
    width: 88px;
  }
  .w-220px {
    width: 220px;
  }
  .w-141 {
    width: 141px;
  }
  .w-218 {
    width: 218px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mt-22 {
    margin-top: 22px;
  }
  .mb-15 {
    margin-bottom: 15px;
  }
  .pl-15 {
    padding-left: 15px;
  }
  .pt-10 {
    padding-top: 10px;
  }
  .padding-10 {
    padding: 10px;
  }
  .fw-600 {
    font-weight: 600 !important;
  }
  .w-100vw {
    width: 70vw;
  }
  .button-add-menu-right {
    border: 1px solid #fea628;
    padding: 9px;
  }
  .total-job {
    background: #ffdba9;
    color: #223354;
  }
  .info-menu-righ-total,
  .info-menu-righ-title {
    color: inherit !important;
  }
  .done {
    background: #b7ffd6;
    color: #2ecc71;
  }
  .charge-of {
    background: #e0ebff;
    color: #2d99ff;
  }
  .late-deadline {
    background: #ffb6ae;
    color: #e74c3c;
  }
  .canceled {
    background: #ffb6ae;
    color: #e74c3c;
  }
  .select-box-skill {
    .select-box {
      border: rgba(243, 242, 242, 0.966);
      border-radius: 4px;
    }
    .Mui-disabled {
      opacity: 0.5;
      fieldset {
        border-color: #e7e7e7;
      }
    }

    .Mui-disabled:hover .MuiSvgIcon-root,
    .MuiInputLabel-root {
      color: rgba(0, 0, 0, 0.26) !important;
    }

    .Mui-disabled:hover fieldset {
      border-color: #e7e7e7;
    }
  }
  .input-search {
    background: white;
    border-radius: 5px;
    width: 100%;
    .MuiInputLabel-root {
      opacity: 0.68;
      font-size: 15px;
    }
    .MuiInputBase-input {
      color: rgba(10, 10, 10, 0.849);
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      padding: 11px 0px;
    }
  }

  .input-search-job {
    background: white;
    border-radius: 5px;
    width: 100%;
    .MuiInputLabel-root {
      opacity: 0.2;
      font-size: 15px;
    }
    .MuiInputBase-input {
      color: rgba(0, 0, 0, 0.24);
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .table-scroll-quantitycandidate {
    min-width: 1040px;
    cursor: pointer;
  }
  .table-scroll-addorder {
    min-width: 1300px;
  }
  .button-cancel {
    text-align: end;
  }
  .button-w {
    width: 204px;
  }
  .filter-job {
    display: flex;
    justify-content: space-between;
    overflow-x: scroll;
    padding-top: 7px;
    margin-top: -7px;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #c4c4c4;
    }
    .MuiInputBase-input {
      height: 30px;
      width: 80px;
      line-height: 30px;
      padding-right: 10px;
    }

    .search {
      width: 100%;
      .MuiOutlinedInput-root {
        height: 54px;
      }
      .MuiSvgIcon-root {
        padding-right: 0;
        font-size: 20px;
      }
      .input-search {
        height: 58px;
      }
    }
    .color-icon {
      .MuiSvgIcon-root {
        color: #8d8d8d;
      }
    }

    .position {
      width: 100%;
      margin-left: 10px;
    }

    .level {
      width: 100%;
      margin-left: 10px;
    }

    .skill {
      width: 100%;
      margin-left: 10px;
      .Mui-disabled .MuiOutlinedInput-notchedOutline {
        border-color: #e7e7e7;
      }
    }

    .status {
      width: 100%;
      margin-left: 10px;
    }

    .deadline {
      width: 100%;
      margin-left: 10px;
      .MuiInputBase-input {
        padding: 12px 6px;
      }
    }
  }

  .MuiInputLabel-root {
    color: #bdbdbd;
  }
  .MuiInputBase-input:disabled {
    -webkit-text-fill-color: #bdbdbd !important;
  }
  .range-date-input {
    border: 1px solid #e7e7e7;
    height: 34px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    span {
      color: #bdbdbd;
    }
  }
  .text-level-hr {
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .box-job-item {
    min-height: 100%;
    background: white;
    padding: 13px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
    &.job-blue {
      --bg-box-item: #0057ff;
    }
    &.job-green {
      --bg-box-item: #2ecc71;
    }
    &.job-deadline {
      --bg-box-item: #e74c3c;
    }
    &.job-canceled {
      --bg-box-item: #e74c3c;
    }
    .content {
      .item-label {
        span {
          font-size: 12px;
          color: #5a6882;
          &:last-child {
            color: #223354;
            font-weight: 600;
          }
        }
      }
    }
    .footer {
      .rtl-left {
        color: #5a6882;
        font-weight: 400;
        font-size: 12px;
      }
      .rtl-right {
        font-weight: 500;
        font-size: 12px;
        color: var(--bg-box-item);
      }
    }
    .header {
      margin-bottom: 10px;
      height: 34px;
    }
    .title span {
      color: #333333;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
    .avatar,
    img {
      width: 26px;
      height: 26px;
    }
    .avatar {
      margin-right: 10px;
      min-width: 24px;
    }
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: var(--bg-box-item);
    }
    .text-response-level {
      text-align: end;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 15px;
    }
  }
  .reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 22px 32px;
    margin-top: 10px;
    height: 36px;
    background: $color-navActive;
    border-radius: 5px;
    color: $color-white;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    .img-reset {
      margin: '0 10px 0 0';
    }

    &:hover {
      background: $color-navActive;
      color: $color-white;
    }

    &:active {
      background: $color-blue !important;
      color: $color-white !important;
    }
  }
  .title {
    font-size: 16px;
    line-height: 1;
  }
  .description {
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    opacity: 0.65;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .delete-job,
  .edit-job {
    color: white;
    text-transform: capitalize;
    font-size: 12px;
  }
  .delete-job {
    background: #e74c3c !important;
    margin-right: 12px;
  }
  .edit-job {
    background: #fea628 !important;
  }
  .people-assigned {
    span {
      color: #8d8d8d;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }
  .h-left h4 {
    font-size: 16px;
    color: #333333;
    font-weight: 700;
    line-height: 19px;
  }

  .total-order {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #223354;
  }

  .filter {
    .mt-3 {
      margin-top: 10px !important;
    }
    .info-menu-right-no-image {
      padding: 9px 9px 9px 12px;
    }
    hr {
      background-color: #e7e7e7;
      border: none;
      height: 1px;
      margin: 10px 0px;
      margin-left: 20px;
    }
  }
}
.modal-add-job {
  .MuiDialog-paper {
    min-width: 54vw !important;
  }
}
.modal-add-applies {
  .modal-body {
    padding-top: 5px;
  }
  .MuiDialog-paper {
    min-width: 468px !important;
  }
  .job-name-add-applies {
    background: #ececec;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #223354;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.modal-create-applies {
  .modal-body {
    padding: 0px;
    padding-top: 0px;
  }
  .body-add-applies-container {
    padding: 1.5rem;
    padding-top: 5px;
  }
  .school {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #e7e7e7;
    }
  }
  .radio-gender-group {
    .MuiFormControlLabel-label {
      color: #333333;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .upload-CV {
    color: #333333;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    .label-CV {
      color: #999999;
    }
    .info-cv-upload {
      display: flex;
      justify-content: flex-start;
      color: #575656;
      .cv-name {
        width: 90%;
        margin-left: 5px;
        .title {
          color: #333333;
          font-weight: 700;
          font-size: 16px;
          line-height: 23px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .loading-upload-cv {
      margin-top: 0;
      margin-bottom: 0;
      text-align: left;
    }
  }
  .job-name-add-applies {
    background: #ececec;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #223354;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.detail-job {
  .h-right {
    display: flex;
  }
  .link-order {
    color: #223354;
    text-decoration: underline;
  }
  .table-list {
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #c4c4c4;
    }
    .MuiTable-root {
      width: 106%;
    }
    .status-deadline-row {
      padding-right: 25px;
      padding-left: 25px;
    }
    .stt-table {
      color: #000000;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
    .cell-table-title {
      color: #223354;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
    .cell-table {
      color: #223354;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
    .link-cell {
      text-decoration: underline;
    }
    .info-order-text-name-order {
      max-width: 220px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .table-container {
    overflow-x: auto;
    max-height: 420px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 30px;
      background: #c4c4c4;
    }
  }

  .MuiTableCell-head {
    white-space: nowrap;
    font-weight: 500;
    color: $color-black;
    background-color: $color-headTable !important;
  }
  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .checkbox-order {
    user-select: none;
    & > span:last-child {
      -webkit-line-clamp: 1;
      word-break: break-all;
    }
    .MuiCheckbox-root.Mui-checked {
      color: #fea628;
    }
  }

  .order-name {
    word-break: break-all;
    max-width: 128px;
  }
  .btn-add-order {
    background: transparent !important;
    border-radius: 50%;
    color: #fea628;
    border: 1px dashed #fea628;
    width: 35px;
    height: 35px;
  }
  .MuiDialog-paper {
    min-width: 870px;
  }
  .MuiTabs-indicator {
    background: #fea628;
  }
  .box-info {
    margin-top: 15px;
    align-items: center;
  }
  .box-item {
    .icon {
      margin-right: 0px;
      margin-top: -3px;
    }
    &:not(.box-item:last-child) {
      padding: 10px;
      padding-left: 0px;
    }
    &:first-child .border-box {
      border-color: #2d99ff;
      background-color: #e4f5ff;
      position: relative;
      .content {
        span:first-child {
          color: #2d99ff;
        }
      }
    }
    &:nth-child(2) .border-box {
      border-color: #fea628;
      background-color: #fff8ee;
      position: relative;
      .content {
        span:first-child {
          color: #fea628;
        }
      }
    }
    &:nth-child(3) .border-box {
      border-color: #009740;
      background-color: #dcffeb;
      position: relative;
      .content {
        span:first-child {
          color: #009740;
          white-space: nowrap;
        }
      }
    }
    &:nth-child(4) .border-box {
      border-color: #009740;
      background-color: #dcffeb;
      position: relative;
      .content {
        span:first-child {
          color: #009740;
        }
      }
    }
    &:nth-child(5) .border-box {
      border-color: #009740;
      background-color: #dcffeb;
      position: relative;
      .content {
        span:first-child {
          color: #009740;
        }
      }
    }
    &:nth-child(6) .border-box {
      border-color: #e74c3c;
      background-color: #ffdedb;
      position: relative;
      .content {
        span:first-child {
          color: #e74c3c;
        }
      }
    }
    .border-box {
      border-width: 1px;
      border-style: solid;
      border-radius: 5px;
      box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
      .content {
        span {
          &:last-child {
            font-weight: bold;
            color: #222433;
          }
        }
      }
    }
    .content {
      span {
        font-size: 12px;
        font-weight: 500 !important;
      }
      .total-cv {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  .tab {
    margin-top: 10px;
  }
  .MuiTab-root {
    color: #8d8d8d;
    font-weight: 500;
    font-size: 17px;
    line-height: 19px;
    &.Mui-selected {
      color: #223354;
    }
    &:first-child:not(.Mui-selected) {
      padding-left: 0;
    }
  }
  .info-general {
    border: 1px solid #f1f1f1;
    padding: 24px;
    margin-top: 15px;
    .item {
      display: flex;
      &:not(.item:last-child) {
        padding-bottom: 15px;
      }
      span:first-child {
        width: 30%;
        color: #5a6882;
        display: block;
      }
      span:last-child {
        width: 55%;
        color: #223354;
        font-weight: bold;
      }
    }
  }
}
.label-gender {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
.text-modal {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}
.create-applies-available {
  .name {
    text-decoration: underline;
    color: #223354;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  .divider {
    margin: 10px 0px;
  }
  .info-applie {
    color: #8d8d8d;
    font-weight: 400;
  }
  .list-applies-available {
    max-height: calc(100vh - 492px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #c4c4c4;
    }
    .MuiCheckbox-root.Mui-checked {
      color: #fea628;
    }
  }
}
.add-applies {
  .applie-cancel {
    color: #8d8d8d;
  }
}

.job-blue {
  color: #0057ff !important;
}
.job-green {
  color: #2ecc71 !important;
}
.job-deadline {
  color: #e74c3c !important;
}
.job-canceled {
  color: #e74c3c !important;
}
.title-create-job {
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  color: #333333;
}
.freelancer-create-job {
  display: flex;
  align-items: flex-end;
}
.tooltip--level {
  color: #333333;
  background-color: #f3f7fb;
  max-width: 400px;
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  border-radius: 4px;
}
