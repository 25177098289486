.containerInfoCandidate {
  padding: 0px 15px;
  margin: 0px !important;
  width: 100% !important;
  .buttonEditInformation {
    border-radius: 50%;
    border-style: none;
    width: 25px;
    height: 25px;
  }
  .containerInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .candidateName {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #223354;
    text-transform: uppercase;
  }

  .titleItem {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #5a6882;
  }

  .contentItem {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #223354;
    word-break: break-word;
    text-align: end;
    padding-left: 5px;
  }
}
