@import 'styles/variable.scss';
.infoInterviewContainer {
  .totalAverageScore {
    background: #f9f9f9;
    color: #333333;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    display: grid;
    justify-content: center;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .infoApplyContainer {
    padding: 20px;
    color: #333333;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    :global {
      .status-red {
        color: $color-red !important;
      }
      .status-blue {
        color: $color-blue1 !important;
      }
      .status-green {
        color: $color-status-blue !important;
      }
    }
  }
}
