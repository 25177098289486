.attachFileTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #333333;
}
.btnCancel {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #8d8d8d;
  background: #ffffff;
  border: none;
}
.btnOk {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #fea628;
  background: #ffffff;
  border: none;
}
.titlePage {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 25px;
  text-transform: uppercase;
}
.infoQuestionTest {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #223354;
}
.infoGeneral {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #223354;
  margin-bottom: 15px;
}
.titleItem {
  color: #333333;
  font-weight: 500;
  font-size: 14px;
  line-height: 50px;
}
.infoItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.indexQuestion {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.question {
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #000000;
}
.indexCreateQuestion {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.titleAnswer {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.boxTitleQuestion {
  display: flex;
  justify-content: space-between;
}

.hiddenQuestion {
  max-height: 0px !important;
  overflow: hidden;
}

.showQuestion {
  max-height: auto;
  min-height: 50px;
  transition: 2s;
}
