.gridContainer {
  .infoTitle {
    color: #000000;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding-left: 20px;
    padding-top: 20px;
  }

  .titleInfoBasic {
    color: #223354;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  .valueInfoBasic {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
    margin-bottom: 8px;
  }

  .radioGroup {
    display: flex;
    justify-content: space-between;
  }
}

.customLabel {
  display: flex;
  align-items: center;
  .avatarLabel {
    display: inline;
  }
  .infoLabel {
    margin-left: 15px;
    .name {
      font-weight: 500;
    }
  }
}
.modalFooter {
  background-color: white;
  gap: 15px;
  border-top: none !important;
  padding: 0 1.25rem 1.25rem 1.25rem !important;

  .btn {
    flex-grow: 1;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    height: 36px;
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    min-width: min-content;
    width: 150px;

    &__action {
      color: white;
      background-color: var(--theme-main-color);
      &:hover:enabled {
        color: var(--theme-main-color);
        border: 1px solid var(--theme-main-color);
        background-color: #fff8ee !important;
      }
    }
    &__danger {
      color: white;
      background-color: #e74c3c;
    }
    &__cancel {
      color: var(--theme-main-color);
      background-color: #fff8ee;
    }
    &__disabled {
      color: white;
      background-color: lightgray;
      cursor: default;
    }
  }
}
