.mainContainer {
  font-family: 'Roboto';
  .headerTitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    color: #333333;
    margin: 20px 0;
    text-transform: uppercase;
  }
  .infoTest {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #223354;
  }
  .infoGeneral {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #223354;
    margin: 25px 0;
  }
  .labelStatus {
    color: #223354;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 25px;
    margin-bottom: 5px;
  }
  .boxQuestion {
    margin-top: 15px;
  }
}
.btnCancel {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #8d8d8d;
  background: #ffffff;
  border: none;
}
.btnOk {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #fea628;
  background: #ffffff;
  border: none;
}

.hiddenQuestion {
  max-height: 0px !important;
  overflow: hidden;
}

.showQuestion {
  max-height: auto;
  min-height: 50px;
  transition: 2s;
}
