.previewContainer {
  :global {
    .MuiDialog-paper {
      max-width: none !important;
      height: max-content;
      width: max-content;
    }
  }
  .iframePreview {
    display: flex !important;
    border: none;
    height: 80vh;
    width: 70vw;
  }
}