@import 'styles/variable.scss';

.mr-26 {
  margin-left: 26px;
}
.w-full {
  width: 100% !important;
}
.mt-10 {
  margin-top: 10px;
}
.div-leader-accept {
  width: 100%;
  height: 55px;
  margin: 5px 15px 5px 25px;
  width: 35px;
  position: relative;
}
.leaderRatePass {
  position: absolute;
  top: 0;
  right: 0;
  background: #e74c3c;
  border-radius: 0px 5px;
  color: white;
  width: 85px;
  height: 26px;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 2px;
}
.body-leaderPass {
  margin-top: 25px;
}
.div-ratting-index {
  color: #333333;
  display: flex;
  align-items: center;
}
.div-score-index {
  color: #526487;
  font-weight: 700;
  font-size: 14px;
  text-align: end;
}
.span-score-index-stand {
  color: #fea628;
}
.div-name-leaderPass {
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
}

.div-name-division-leaderPass {
  font-size: 12px;
  font-weight: 400;
  font-family: Roboto;
  color: #8d8d8d;
}

.title-offer-leaderPass {
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto;
  color: #333333;
  display: inline-block;
}
.content-offer-leaderPass {
  font-size: 14px;
  font-weight: 700;
  font-family: Roboto;
  color: #333333;
}
.tooltip-item {
  color: #222433;
  font-size: 14px;
  font-weight: 500;
  padding-top: 5px;
  background-color: #f3f7fb;
  border-radius: 5px;
}
.apply-suggest-tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.div-content-score {
  margin-bottom: 10px;
  justify-content: space-between;
}
.styleDateAssess {
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 400;
  margin-right: 96px;
}
.styleDateAssessAssigner {
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 400;
  margin-right: 10px;
}
.generalAssessment {
  font-size: 16px;
  font-family: Roboto;
  font-weight: 700;
  color: #333333;
  padding: 10px;
}
.contentGeneralAssessment {
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  color: #333333;
  padding: 0px 10px 20px 10px;
  word-break: break-word;
}
.circle-edit-offer-button {
  padding: 0px 3px 3px 3px;
  border-radius: 100%;
  vertical-align: middle;
  float: right;
  margin-right: 10px;
  margin-top: 20px;
  width: 25px;
  height: 25px;
  border: 2px solid $color-borderTable;
  position: absolute;
  right: 5px;
  svg {
    color: $color-menuActive;
    font-size: 16px;
  }

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}
.color-button {
  svg {
    color: $color-truncate !important;
  }
}
