@import 'styles/variable.scss';

.manager-group-test {
  .mt-16 {
    margin-top: 16px;
  }
  .mt-15 {
    margin-top: 15px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-25-i {
    margin-top: 25px !important;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .fs-18 {
    font-size: 18px;
  }
  .w-92 {
    width: 92px;
  }
  .h-36 {
    height: 36px;
  }
  .z-9 {
    z-index: 9;
  }
  .mr-12 {
    margin-right: 12px;
  }
  .pl-10 {
    padding-left: 10px;
  }
  .pt-10 {
    padding-top: 10px;
  }
  .flex-button {
    display: flex;
  }
  .ml-mr-20 {
    margin-left: -20px;
    margin-right: -20px;
  }
  .table-list {
    .stt-table {
      color: #000000;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
    .cell-table {
      color: #223354;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .create-question {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
  }
  .preview-img {
    .item {
      max-width: 200px;
      margin-right: 10px;
    }
  }
  .date-search {
    .MuiInputLabel-root {
      opacity: 0.38;
      font-size: 15px;
    }
    .MuiInputBase-input {
      height: 40px;
      padding: 0 0 0 15px;
      color: rgb(158, 158, 158);
    }
    .MuiFormLabel-root:not(.Mui-focused):not(.MuiFormLabel-filled) {
      transform: none;
      line-height: 40px;
      padding-left: 10px;
      color: #bdbdbd;
    }
  }
  .reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 22px 32px;
    margin-top: 10px;
    height: 36px;
    background: $color-navActive;
    border-radius: 5px;
    color: $color-white;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    .img-reset {
      margin: '0 10px 0 0';
    }

    &:hover {
      background: $color-navActive;
      color: $color-white;
    }

    &:active {
      background: $color-blue !important;
      color: $color-white !important;
    }
  }
  .errors {
    color: #e74c3c;
    margin: 0;
  }
  .center-image {
    position: absolute;
    top: 0;
    right: 3px;
  }
  .close {
    width: 30px;
    height: 30px;
    &:before,
    &:after {
      position: absolute;
      content: '';
      width: 15px;
      height: 2px;
      background: #222222;
      right: 0;
      top: 10px;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  .type-guide {
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    outline: none;
  }
  .box-item-add-question {
    border: 1px solid #e7e7e7;
    padding: 22px;
    border-radius: 4px;
    margin-top: 25px;
  }
  .list-test {
    ul {
      list-style-type: none;
    }
    margin-top: 15px;
    overflow-y: scroll;
    max-height: 80vh;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #c4c4c4;
    }
    .list-answer {
      max-height: 190px;
      overflow: auto;
      width: 100%;
      margin-right: 8px;
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #c4c4c4;
      }
    }
  }
  .btn-cancel-create-test {
    padding: 0.375rem 0.75rem;
  }
  .attach-image {
    span {
      margin-top: 26px;
      margin-bottom: 10px;
    }
    .file-upload {
      width: 93px;
      height: 64px;
      border: 1px dashed #e7e7e7;
      border-radius: 4px;
      margin-right: 10px;
      overflow: hidden;
      margin-bottom: 10px;
      input {
        cursor: pointer;
        z-index: 2;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
        &::-webkit-file-upload-button {
          cursor: pointer;
        }
      }

      img {
        object-fit: contain;
      }
    }
  }
  .answer {
    margin-top: 25px;
    margin-bottom: 5px;
  }
  .MuiTab-root {
    &:not(.Mui-selected) {
      background: #fff8ee;
      color: #fea628;
      margin-right: 5px;
    }
    border-radius: 4px;
  }
  .Mui-selected {
    color: #ffffff !important;
    background: #fea628;
  }
  .MuiTabs-indicator {
    display: none;
  }
  .tab-option-question {
    margin-bottom: 14px;
    .mr-10 {
      margin-right: 10px;
    }
  }
  .group-question {
    .question-item:not(.question-item:last-child) {
      margin-bottom: 40px;
    }
    .action {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      margin-left: 20px;
    }
    .action.add {
      border: 1px dashed #fea628;
      border-radius: 50px;
      color: #fea628;
      background: transparent;
    }
    .action.delete {
      background: #fff8ee;
      border-radius: 4px;
      border: 1px solid #fff8ee;
      svg {
        font-size: 22px;
        color: #fea628;
      }
    }
  }
  .btn-submit-add-question {
    height: 36px;
    margin-top: 16px;
    margin-left: 22px;
    margin-bottom: 10px;
  }
  .append-question {
    padding: 6px 8px;
    border-radius: 0.25rem;
    border: 1px solid #f9fbfc;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    span {
      margin-left: 5px;
      color: #333333;
      font-size: 16px;
    }
    svg {
      font-size: 16px;
    }
    &:hover {
      span {
        color: white;
      }
      background-color: #fea628;
      color: white;
      border: 1px solid #fea628;
      border-radius: 0.25rem;
    }
  }
  .disabled-append-question {
    position: relative;
    opacity: 0.6;
    cursor: default;
    padding: 6px 8px;
    border-radius: 0.25rem;
    border: 1px solid #f9fbfc;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    span {
      margin-left: 5px;
      color: #bdbdbd;
      font-size: 16px;
    }
    svg {
      font-size: 16px;
      color: #bdbdbd;
    }
  }
}
