.labelDecision {
  background: #039900;
  border-radius: 2px;
  padding: 6px 30px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.labelPass {
  background: #039900;
}
.labelFail {
  background: #e74c3c;
}
.labelNotRated {
  background: rgba(12, 138, 255, 0.4);
}
.noteAssessContainer {
  width: 300px;
  background-color: white;
  padding: 13px;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  border-radius: 4px;

  .title {
    color: #333333;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
  }

  .content {
    color: #333333;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}
.rowCollapseContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #f4f4f4;
  padding: 8px 12px 8px 25px;
  cursor: pointer;
  margin-bottom: 10px;
}
.rowCollapseNotRated {
  background: #e4f5ff;
}
.assessTableContainer {
  .backgroundPass {
    background: rgba(65, 255, 62, 0.05);
  }
  .backgroundFail {
    background: rgba(255, 62, 62, 0.05);
  }
  .offerLeaderContainer {
    padding: 6px 12px 12px 15px;
    .headerInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .dateAssess {
        margin-top: 25px;
        color: #8d8d8d;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }
    }
    .headerAssess {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      .dateAssess {
        margin-top: 25px;
        color: #8d8d8d;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }
    }
    .offerInfo {
      margin-top: 10px;
      .itemContainer {
        display: flex;
        // align-items: center;
        .titleItem {
          color: #000000;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
        }
        .contentItem {
          color: #223354;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }
        .overflowText {
          cursor: pointer;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
  .ratingChartContainer {
    padding: 25px 20px 10px 20px;
    align-items: center;
    :global {
      .h-0 {
        height: 0px;
      }
    }
    .listStats {
      padding-left: 20px;
      .rowStat {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .titleStat {
          margin-left: 5px;
          margin-right: 8px;
          color: #333333;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
        }
        .noteAssessBtn {
          cursor: pointer;
        }
      }
      .avgScore {
        border-top: 1px solid #e9ebee;
        padding-top: 15px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .titleAvgScore {
          color: #223354;
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
        }
      }
      .levelAssess {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        .title {
          color: #223354;
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
        }
        .levelText {
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
          color: #333333;
        }
      }
      .score {
        color: #333333;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
      }
      .scoreStand {
        color: #fea628;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
      }
      .avgPoint {
        color: #333333;
        font-weight: 700;
        font-size: 18px;
        line-height: 16px;
      }
      .avgPointStand {
        color: #fea628;
        font-weight: 700;
        font-size: 18px;
        line-height: 16px;
      }
    }
  }
  .generalAssessment {
    padding: 10px 20px 0px 20px;
    .title {
      color: #8d8d8d;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 5px;
      margin-right: 10px;
    }
    .content {
      color: #333333;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      max-width: 525px;
    }
  }
}
