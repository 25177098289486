@import 'styles/variable.scss';
.tooltipJobName {
  color: #333333;
  background-color: #f3f7fb;
  max-width: 400px;
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  border-radius: 4px;
}
.container {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  margin-top: 10px;
  .header {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    .titleHeader {
      color: #223354;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      margin-left: 8px;
    }
  }
  .backgroundHightLight {
    background: #f9f9f9;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    min-height: 80px;
  }
  .bodyContainer {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }
  .itemHeaderContainer {
    display: flex;
    align-items: center;
  }
  .itemContainer {
    display: flex;
    min-height: 30px;
    max-width: 483px;
  }
  .titleItem {
    color: #5a6882;
    font-weight: 400;
    font-size: 14px;
  }
  .contentItem {
    color: #223354;
    font-weight: 700;
    font-size: 14px;
    padding-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .textOver {
    white-space: nowrap;
  }
  .jobName {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
  }
  .link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    font-size: 14px;
  }
  .CVContainer {
    display: flex;
    padding-left: 20px;
    padding-bottom: 20px;
    .iconCV {
      width: 70px;
      height: 70px;
    }
    .titleCV {
      color: #333333;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
    }
    .contentCV {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #575656;
    }
    .groupButtonCV {
      display: flex;
      .previewButton {
        margin-left: 12px;
      }
      .iconDownload {
        height: 22px;
        &:hover {
          transform: scale(1.2);
          cursor: pointer;
        }
      }
    }
  }

  :global {
    .status-red {
      color: $color-red !important;
    }
    .status-blue {
      color: $color-blue1 !important;
    }
    .status-green {
      color: $color-status-blue !important;
    }
    .divider {
      margin: 15px 20px;
    }
  }
}
