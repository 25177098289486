.disableLine {
  cursor: default !important;
  color: #bdbdbd !important;
  img {
    opacity: 0.4;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
}

.disableGroupCheckBox {
  filter: invert(49%) sepia(1%) saturate(0%) hue-rotate(147deg) brightness(103%) contrast(90%);
  opacity: 0.4;
}

.modalAddEmployee {
  :global {
    .MuiPaper-root {
      width: 500px !important;
    }
  }
  .modalFooter {
    background-color: white;
    gap: 15px;
    border-top: none !important;
    padding: 0 1.25rem 1.25rem 1.25rem !important;

    .btn {
      flex-grow: 1;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      height: 36px;
      box-sizing: border-box;
      border: none;
      border-radius: 5px;
      min-width: min-content;
      width: 150px;

      &__action {
        color: white;
        background-color: var(--theme-main-color);
        &:hover:enabled {
          color: var(--theme-main-color);
          border: 1px solid var(--theme-main-color);
          background-color: #fff8ee !important;
        }
      }
      &__danger {
        color: white;
        background-color: #e74c3c;
      }
      &__cancel {
        color: var(--theme-main-color);
        background-color: #fff8ee;
      }
      &__disabled {
        color: white;
        background-color: lightgray;
        cursor: default;
      }
    }
  }
}
