@import './../../styles/variable.scss';

:global {
  .Mui-focused {
    color: '#66d997' !important;
  }
}

.selectComponent {
  :global {
    .MuiOutlinedInput-root {
      background-color: white;
      .MuiSelect-select {
        padding: 12px 15px 12px 15px;
        font-size: 14px;
      }

      .MuiSvgIcon-root {
        font-size: 35px;
      }

      fieldset {
        border-color: $color-wheat;
      }

      &:hover {
        fieldset,
        .MuiSvgIcon-root {
          border-color: $color-green;
          color: $color-green !important;
        }
      }

      .MuiOutlinedInput-root.Mui-disabled {
        fieldset {
          border-color: $color-wheat;
        }
      }
    }

    .MuiOutlinedInput-root.Mui-focused {
      .MuiOutlinedInput-notchedOutline,
      .MuiSvgIcon-root {
        border-color: $color-green;
        color: $color-green;
      }
    }
    .MuiOutlinedInput-root.Mui-error {
      .MuiOutlinedInput-notchedOutline,
      .MuiSvgIcon-root {
        border-color: $color-red;
        color: $color-red;
      }

      &:hover {
        fieldset,
        .MuiSvgIcon-root {
          border-color: $color-red;
          color: $color-red !important;
        }
      }
    }

    .MuiInputLabel-root.Mui-error.Mui-focused {
      color: $color-red !important;
    }
  }
  &:hover {
    :global {
      .MuiInputLabel-root {
        color: $color-green;
      }
      .MuiInputLabel-root.Mui-error {
        color: $color-red;
      }
    }
  }

  .selectNoneLabel {
    :global {
      .MuiOutlinedInput-notchedOutline legend {
        width: 0;
      }
    }
  }
}

.sizeSelect {
  :global {
    .MuiOutlinedInput-root {
      .MuiSelect-select {
        padding: 8px 15px 7px 15px;
        font-size: 14px;
      }
    }
  }
}

.selectComponentAuto {
  :global {
    .MuiInputLabel-root {
      font-size: 16px;
      &.Mui-focused {
        color: $color-green !important;
      }
    }
    .MuiOutlinedInput-root {
      font-size: 14px;
      padding: 14px 40px 14px 15px !important;
    }
    .MuiAutocomplete-input {
      padding: 0px !important;
    }
    .MuiButtonBase-root {
      margin-top: -5px !important;
    }
  }
}

.supRequired {
  position: relative;
  top: -4px;
}
