@import './variable.scss';

html,
body,
#root {
  :global {
    .MuiBox-root {
      height: 100%;
    }
  }
  height: 100%;
}

.container-fluid {
  padding: 0;
}

.header {
  padding-right: 20px;
}

.text-up {
  text-transform: uppercase;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.strike {
  height: 1px;
  width: 100%;
  background-color: $color-grey;
  margin: 15px 0;
}

.bg-light-gray {
  background-color: $bg-rowTable;
}

.circle-orange {
  color: $color-yellow !important;
  background: $color-yellow1 !important;
  border-color: $color-yellow !important;
}
.circle-green {
  color: $color-green !important;
  background: $color-green1 !important;
  border-color: $color-green !important;
}

.circle-pink {
  color: $color-pink !important;
  background: $color-pink1 !important;
  border-color: $color-pink !important;
}

.circle-blue {
  color: $color-blue2 !important;
  background: $color-blue3 !important;
  border-color: $color-blue2 !important;
}

.circle-grey {
  color: $color-grey !important;
  background: $color-tableStripped !important;
  border-color: $color-grey !important;
}

.border-orange {
  border-color: $color-yellow !important;
}

.border-green {
  border-color: $color-green !important;
}

.border-pink {
  border-color: $color-pink !important;
}

.border-blue {
  border-color: $color-blue2 !important;
}

.border-grey {
  border-color: $color-grey !important;
}

.bg-code-green {
  background: $color-green !important;
}

.bg-code-pink {
  background: $color-pink !important;
}

.bg-code-blue {
  border-color: $color-blue2 !important;
}

.bg-code-grey {
  background: $color-grey !important;
}

.bg-white {
  background-color: $bg-white !important;
}

.border-circle {
  border-radius: 999px !important;
}

.border-smooth-square {
  border-radius: 5px !important;
}

@media (min-width: 768px) {
  .main-content {
    .container-fluid {
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  }
}

.rdtCounters .rdtCounter:last-child .rdtCount {
  color: inherit;
  border: none;
}

.rdtCounter .rdtCount {
  padding: 0;
}

.disable {
  cursor: no-drop !important;
  opacity: 0.6;
}

.disable-button {
  cursor: no-drop !important;
  opacity: 0.6;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: white;
  background-color: #fea628;
  border-radius: 5px;
  padding: 7px 32px;
  box-shadow: none;
  border: 1px solid #fff8ee;
  white-space: nowrap;
  margin-left: 10px;
  height: 100%;
  &:hover,
  &:active {
    color: white !important;
    background-color: #fea628 !important;
    border: 1px solid #fff8ee !important;
  }
}

body {
  background-color: $bg-body;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ml-13 {
  margin-left: 13px;
}

.ml-40 {
  margin-left: 40px;
}

.mr--15 {
  margin-right: -15px !important;
}

.mr-10 {
  margin-right: 10px;
}

.d-inline {
  display: inline;
}

.clear-both {
  clear: both;
}

.table-responsive {
  box-shadow: 0px 5px 25px $color-box-shadow;
  border-radius: 5px;
}

.col-delete-edit {
  width: 50px;
}

.hidden {
  display: none !important;
}

.display-gird {
  display: grid;
}

p {
  font-weight: normal;
  font-size: 14px;
  word-wrap: break-word;
}

.white-space-nowrap {
  white-space: nowrap;
}

.bg-menu {
  background-color: $bg-menu;
  z-index: 10;
}
.bg-menu-slide {
  background-color: $bg-menu;
}

.fs-14 {
  font-size: 14px !important;
  line-height: 22px;
}

.fs-16 {
  font-size: 16px !important;
  line-height: 22px;
}

.color-charcoal {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  color: $color-charcoal;
}

.color-yellow {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  color: $color-yellow;
}

.color-blue-gray {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  color: $color-blueGray;
}

.color-blue-gray {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  color: $color-blueGray;
}

.color-blue1 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  color: $color-blue1 !important;
}

.color-green {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  color: $color-green !important;
}

.color-red {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  color: $color-red !important;
}

.color-navy {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  color: $color-navy !important;
}

.color-silver {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  color: $color-silver !important;
}

.btn-bg-yellow2 {
  background-color: $bg-yellow2;
  color: $color-yellow;
  border: 1px solid $bg-yellow2;

  &:active {
    background-color: $bg-yellow2 !important;
    color: $color-yellow !important;
    border: 1px solid $bg-yellow2 !important;
  }

  &:hover {
    background-color: $color-yellow;
    color: $color-white;
    border: 1px solid $color-yellow;
  }
}

.btn-bg-yellow1:not(:disabled) {
  color: $color-white;
  background-color: $bg-yellow1;
  border: 1px solid $color-yellow1;

  &:active,
  &:focus {
    background-color: $bg-yellow1 !important;
    border: 1px solid $color-yellow1 !important;
    color: $color-white !important;
    box-shadow: none;
  }

  &:hover {
    background-color: $bg-white;
    color: $color-yellow;
    border: 1px solid $color-yellow;
  }
}

.btn-dropdown {
  margin-right: 15px;

  button {
    box-shadow: 0 1px 3px #32325d26, 0 1px 0 #00000005;
    background-color: $bg-white !important;
    transition: box-shadow 0.15s ease;

    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 22px;
    color: $color-silver;
    font-weight: normal;
  }
}

.avatar {
  overflow: hidden;
  color: #fff;
  background-color: white;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  img {
    object-fit: cover;
    width: 40px;
    height: 40px;
  }
}

.table {
  color: $color-charcoal;
  margin-bottom: 0;

  td,
  th {
    font-size: 14px;
  }
}

.table-list {
  color: black;
  background: #ffffff;
  border: 1px solid $color-borderTable;
  box-sizing: border-box;
  border-radius: 5px;
  box-sizing: border-box;
  border-radius: 5px;

  thead {
    tr {
      background-color: $bg-salmon;
      th {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $color-navy;
        text-transform: inherit;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: $bg-rowTable;
      }
    }
  }
}

.table-border-show {
  box-sizing: border-box;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  border-radius: 5px;
  width: 100%;

  .MuiPaper-root {
    box-shadow: none;
  }
  .MuiTableContainer-root {
    border-radius: 5px;
  }
}

.table-border-bottom-none {
  .MuiTableCell-root {
    border-bottom: 0px;
  }
}

.table-pagination {
  p {
    margin-bottom: 5px;
    color: $color-nav;
  }
  .MuiSelect-standard {
    color: $color-nav;
  }
  .MuiButtonBase-root {
    .MuiSvgIcon-root {
      color: $color-yellow;
    }
    &.Mui-disabled {
      .MuiSvgIcon-root {
        color: $bg-yellow1h;
      }
    }
  }
  .MuiTablePagination-displayedRows {
    position: absolute;
    left: 17px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    flex-shrink: 0;
  }
  .MuiTablePagination-actions {
    margin-left: 0px !important;
  }
}

.border-show {
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0px 5px 25px 4px rgba(34, 51, 84, 0.15);
  border-radius: 5px;
}

.border-table {
  border: 1px solid $color-wheat;
  box-sizing: border-box;
  border-radius: 5px;
}

.col-action {
  color: $color-silver;
  svg {
    font-size: 25px;
  }
  .remove,
  .edit {
    &:hover {
      cursor: pointer;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
}

.text-bold,
.text-input-bold input {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: bold !important;
}

.title-body {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $color-titleBody;
  margin-bottom: 20px;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.text-truncate {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #8d8d8d;
}

.box-session {
  border: 1px solid $color-grey;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 5px;
  border: 1px solid $color-grey;

  .card-body-top {
    padding: 15px 20px 0px 20px;
  }
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.pd-0 {
  padding: 0px !important;
}

.pd-2 {
  padding: 2px !important;
}

.pd-15 {
  padding: 15px;
}

.pdb-20 {
  padding-bottom: 20px;
}

.width-92 {
  width: 92px;
}

.width-132 {
  width: 150px;
  font-size: 14px !important;
  text-transform: none !important;
}

.height-36 {
  height: 36px;
}

.form-control,
.form-control:focus {
  color: $color-charcoal;
}

.input-border {
  border: 1px solid $color-inputColor;

  &:active,
  &:visited,
  &:focus {
    border-color: $color-green;
  }
}

.input-border-radius {
  border-radius: 5px;
}

.box-show-none {
  .input-group-alternative,
  .form-control-alternative {
    box-shadow: none;
  }
  .input-group-prepend {
    margin-right: 0px;
  }

  .input-group {
    border: 1px solid $color-wheat;

    .input-group-prepend + .rdt .form-control {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  .input-group-text {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
}

.rdtOpen input {
  border-top: 1px solid $color-green !important;
  border-bottom: 1px solid $color-green !important;
  border-left: 1px solid $color-green !important;
}
.rdtOpen ~ .input-group-prepend .input-group-text {
  border-right: 1px solid $color-green !important;
  border-top: 1px solid $color-green !important;
  border-bottom: 1px solid $color-green !important;
}

.fieldset-group:focus-within label {
  color: $color-green;
}

.calendar-input {
  width: 20px;
}

.fieldset-group {
  .form {
    position: relative;
    top: 0px;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);

    &__input {
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid $color-inputColor;
      box-shadow: none;

      &:focus {
        border-color: $color-green;
      }
    }

    &__label {
      position: absolute;
      left: 0.8rem;
      top: 0.8rem;
      padding: 0 0.5rem;
      color: $color-truncate;
      cursor: text;
      transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
      background-color: $bg-white;
    }

    ::placeholder {
      color: $color-white;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: $color-white;
    }

    ::-ms-input-placeholder {
      color: $color-white;
    }
  }

  .form__input:focus ~ .form__label,
  .form__input:not(:placeholder-shown).form__input:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
}

.error {
  color: $color-red;
  margin-bottom: 0;
  font-size: 14px;
}

.error-input {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
}

.error-no-data {
  color: $color-red !important;
  padding: 30px !important;
  text-align: center;
  font-size: 1rem !important;
}

.w-170 {
  width: 170px;
}

.ct-form-controller-filter {
  .form-control {
    height: auto;
    padding: 0.425rem 0.75rem;
  }
  .input-group-text {
    padding: 0.225rem 0.75rem;
  }
}

.auto-scroll-hidden {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.auto-scroll-hidden::-webkit-scrollbar {
  display: none;
}

.auto-scroll-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 1060px) {
  .title-body-height {
    height: 48px;
  }
}

.nav-fixed {
  position: sticky;
  background: $bg-body;
  top: 55px;
  z-index: 100;
  padding-top: 10px;
  padding-bottom: 5px;
  z-index: 120;
}

.nav-fixed-custom-top {
  top: 55px;
}

.menu-right {
  width: 95% !important;
  margin: 0 20px !important;
}

.view-render {
  // padding-left: 10px !important;
  padding-left: 244px !important;
  width: 100%;
}

.info-menu-right-no-image {
  border-radius: 5px;
  padding: 15px;
  display: flex;
  position: relative;
  .info-menu-righ-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $color-charcoal;
    word-wrap: break-word;
  }
  .info-menu-righ-total {
    position: absolute;
    right: 17px;
    color: $color-charcoal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
}

.selected {
  background: $color-totalEmployee;
}

.filter {
  padding: 0 10px 0 0;
}

.input-select-multi {
  appearance: none;
  width: 100%;
  cursor: pointer;
  border: 1px solid $color-inputColor;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: $color-white;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 0;
  padding-left: 13px;
  height: 40px;
  color: $color-truncate;
  margin-right: 0px;
  input {
    cursor: pointer;
  }
}

.input-select-multi:hover {
  cursor: pointer;
}

.layout-secruit {
  // height: calc(100% - 75px);
  // display: flex;
  // width: 100%;
}

.sc-menu {
  min-width: 244px;
}

.content-recruit {
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  .layout-content {
    width: 78%;
  }
  .layout-filter {
    width: 20%;
  }
}

.list-users {
  height: 300px;
}

.btn-reset {
  .reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 22px 32px;
    margin-top: 10px;
    height: 36px;
    background-color: $color-navActive;
    border-radius: 5px;
    color: $color-white;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    .img-reset {
      margin: '0 10px 0 0';
    }

    &:hover {
      background: $color-navActive;
      color: $color-white;
    }

    &:active {
      background: $color-blue !important;
      color: $color-white !important;
    }
  }
}

.MuiTooltip-tooltip {
  padding: 0px !important;
  border-radius: 5px !important;
  max-width: none !important;
}
.MuiTooltip-arrow {
  color: #f3f7fb !important;
  &::before {
    border: 1px solid #e7e7e7;
  }
}
.MuiTooltip-tooltipArrow {
  border: 1px solid #e7e7e7;
}

iframe:first-of-type {
  display: none !important;
}

.ellipsis-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.btn-cancel {
  color: #8d8d8d;
  background: transparent;
  border: none;
  font-weight: 400 !important;
  &:hover,
  &:focus,
  &:active {
    background: transparent !important;
    border: none !important;
    color: #8d8d8d !important;
    box-shadow: none !important;
  }
}
.btn-ok {
  color: #fea628;
  background: transparent;
  border: none;
  font-weight: 500;
  &:hover,
  &:focus,
  &:active {
    background: transparent !important;
    border: none !important;
    color: #fea628 !important;
    box-shadow: none !important;
  }
}

.w-300 {
  width: 300px;
}

.w-200 {
  width: 200px;
}

.w-37-percent {
  width: 37%;
}

.w-auto {
  width: auto !important;
}
