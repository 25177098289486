@import 'styles/variable.scss';

.listReferralBonus {
  .headTitle {
    .title {
      white-space: nowrap;
      color: #333333;
      font-family: 'Roboto';
      font-weight: 700;
      font-size: 18px;
      margin-right: 30px;
      text-transform: uppercase;
    }
    .schedule {
      flex-basis: 80%;
      display: flex;
      flex-direction: column;
      gap: 15px;

      &__navigator {
        display: flex;
        flex-direction: row;
        color: #223354;
        font-size: 14px;
        line-height: 22px;

        .previous,
        .next {
          width: 25px;
          text-align: center;
          border: none;
          background-color: inherit;
        }

        .currentDate {
          margin: 0 30px;
        }
      }
    }
  }

  .btnConfirmInfoDisable {
    background-color: #fff8ee !important;
    color: #fea628 !important;
    border-color: #fff8ee !important;
    &:active,
    &:focus {
      background-color: #fff8ee !important;
      color: #fea628 !important;
      border-color: #fff8ee !important;
    }
  }

  .btnConfirmInfo {
    margin-top: -5px;
  }

  .export {
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 11px;
    font-weight: 500;
    cursor: not-allowed;
    background: #dfeaff;
    color: #2d99ff;
    opacity: 1;
    border: none;

    &:active,
    &:hover {
      background: #2d99ff;
      color: white;
    }

    img {
      width: 18px;
      height: 18px;
    }
    svg {
      margin-right: 8px;
    }

    &:hover,
    &:active,
    &:focus {
      svg path {
        fill: white;
      }
    }
  }

  .reset {
    margin-top: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 22px 32px;
    margin-top: 10px;
    height: 36px;
    background: $color-navActive;
    border-radius: 5px;
    color: $color-white;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    .img-reset {
      margin: '0 10px 0 0';
    }

    &:hover {
      background: $color-navActive;
      color: $color-white;
    }

    &:active {
      background: $color-blue !important;
      color: $color-white !important;
    }
  }

  .searchNameCandidate {
    margin-top: 10px;
    :global {
      .MuiFormControl-root {
        background-color: white;
      }
      .MuiInputBase-input {
        font-size: 14px;
      }
      .MuiInputAdornment-root {
        margin-right: -15px;
      }
      ::-webkit-input-placeholder {
        /* Edge */
        color: black;
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: black;
      }
      ::placeholder {
        color: black;
      }
      .MuiInputBase-inputTypeSearch {
        opacity: 0.5;
        padding: 12px 15px !important;
      }
    }
  }
  .tableBonus {
    width: 100%;
    .colSTT {
      width: 50px;
    }
    .colName {
      min-width: 120px;
    }
    .colCodeEmployee {
      min-width: 100px;
    }
    .colDivision {
      min-width: 95px;
    }
    .colPosition {
      min-width: 95px;
    }
    .colSkill {
      min-width: 95px;
    }
    .colLevel {
      min-width: 95px;
    }
    .colDate {
      min-width: 110px;
    }
    .colSalaryBonus {
      min-width: 105px;
    }
    .colMark {
      min-width: 110px;
    }
  }
}

.searchPopup {
  :global {
    .Mui-disabled {
      color: rgba(0, 0, 0, 0.38);
      opacity: 1;
      -webkit-text-fill-color: rgba(0, 0, 0, 0.38) !important;
    }
  }
}
