:root {
  --theme-main-color: #fea628;
}

.container {
  display: flex;
  gap: 30px;
}

.schedule {
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__navigator {
    display: flex;
    flex-direction: row;
    color: #223354;
    font-size: 14px;
    line-height: 22px;

    .previous,
    .next {
      width: 25px;
      text-align: center;
      border: none;
      background-color: inherit;
    }

    .currentDate {
      margin: 0 30px;
    }
  }

  &__calendar {
    .cell {
      flex-basis: calc(100% / 7);
      position: relative;
      // width: 117px;
      height: 89px;
      border-radius: 5px;
    }

    .header,
    .body {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__row {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }

    .header {
      margin-bottom: 10px;

      .cell {
        background-color: var(--theme-main-color);
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        line-height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
      }
    }

    .body {
      .cell {
        background-color: #fcfcfc;
        border: 1px solid rgba(0, 0, 0, 0.12);
        padding: 5px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #223354;

        &__items {
          margin-top: 5px;

          & > ul {
            list-style: none;
            padding: 0;
            margin: 0;
            & > li {
              font-size: 12px;
              line-height: 14px;
              color: #333333;
              font-weight: 400;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }

        &__hasMore {
          height: 14px;
          background-color: inherit;
          position: absolute;
          bottom: 0;
          width: 100%;
          margin-left: -5px;
          text-align: center;

          & > div {
            position: relative;
            & > span {
              position: relative;
              bottom: 7px;
            }
          }
        }
      }

      .outOfMonth {
        background-color: lightgray;
        cursor: default;
      }

      .isSelected {
        background-color: #fff8ee;
      }

      .today {
        background-color: #2d99ff;
        color: white;
        li {
          color: rgba(233, 233, 233, 0.6) !important;
        }
      }
    }
  }
}

.interviewList {
  flex-basis: 20%;
  min-width: 204px;

  .selectedDate {
    background-color: var(--theme-main-color);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;

    &__item {
      box-sizing: border-box;
      background-color: #ffffff;
      border: 1px solid #e7e7e7;
      border-left: 3px solid var(--theme-main-color);
      border-radius: 5px;
      padding: 10px;
      position: relative;
      cursor: pointer;

      .candidate {
        color: #333333;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }

      .position {
        color: #8d8d8d;
        font-size: 12px;
        line-height: 14px;
        margin: 3px 0;
      }

      .time {
        color: #333333;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        float: right;
      }

      .room {
        margin-top: 13px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #000000;
        & > i {
          color: #66d997;
        }
      }

      .interviewers {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        margin: 5px 0;

        .interviewer img {
          width: 25px;
          height: 25px;
          object-fit: cover;
          border-radius: 100%;
        }
      }

      .link {
        margin-top: 20px;
        font-size: 12px;
        line-height: 14px;
        & > a {
          color: #2d99ff;
          text-decoration: underline;
        }
      }
    }
  }
}

.detailModal {
  :global {
    .MuiDialog-paper {
      width: 468px;
      min-width: unset;
    }
    .modal-title-head {
      color: #f8f8f8;
    }
  }
}

.detailFields {
  font-family: 'Roboto', sans-serif;

  .field {
    &__label {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 6px;
      color: rgba(90, 104, 130, 1);
    }

    &__value {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      min-height: 16px;
      margin-bottom: 14px;
      color: #223354;
      word-break: break-word;
    }

    a {
      color: #2d99ff;
      text-decoration: underline;
    }
  }
}

.interviewerInfo {
  &__title {
    color: var(--theme-main-color);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 15px;
    margin-bottom: 9px;
  }

  .interviewers {
    .interviewer {
      width: 100%;
      display: flex;
      gap: 10px;
      margin: 8px 0;

      &__avatar {
        display: inline-block;
        & > img {
          width: 35px;
          height: 35px;
          object-fit: cover;
          border-radius: 100%;
        }
      }

      &__name {
        color: #223354;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }

      &__position {
        color: rgba(90, 104, 130, 1);
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

.customLabel {
  display: flex;
  align-items: center;
  .avatarLabel {
    display: inline;
  }
  .infoLabel {
    margin-left: 15px;
    .name {
      font-weight: 500;
    }
  }
}

.interviewer {
  :global {
    .MuiSvgIcon-root {
      display: none;
    }
    .MuiChip-deleteIcon {
      display: inline-block;
    }
  }
}

.modalFooter {
  background-color: white;
  gap: 15px;
  border-top: none !important;
  padding: 0 1.25rem 1.25rem 1.25rem !important;

  .btn {
    flex-grow: 1;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    height: 36px;
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    min-width: min-content;
    width: 198px;

    &__action {
      color: white;
      background-color: var(--theme-main-color);
      &:hover:enabled {
        color: var(--theme-main-color);
        border: 1px solid var(--theme-main-color);
        background-color: #fff8ee !important;
      }
    }
    &__danger {
      color: white;
      background-color: #e74c3c;
    }
    &__cancel {
      color: var(--theme-main-color);
      background-color: #fff8ee;
    }
    &__disabled {
      color: white;
      background-color: lightgray;
      cursor: default;
    }
  }
}

.cancelModal {
  &__reason {
    font-size: 14px !important;
    height: 150px !important;
    resize: none;
  }

  :global {
    .modal-body {
      padding: 2rem 1.5rem 0.75rem 1.5rem;
    }
    .MuiDialog-paper {
      width: 468px;
      min-width: unset;
    }
    .modal-title-head {
      color: #f8f8f8;
    }
  }
}

.editModal {
  :global {
    .modal-body {
      padding: 1rem 1.5rem 0.75rem 1.5rem;
    }
    .MuiDialog-paper {
      width: 468px;
      min-width: unset;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 7px;
  }

  &__info {
    margin-bottom: 30px;
  }

  .detailFields .field {
    &__label {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      color: #223354;
      margin-bottom: 0;
      margin-top: 3px;
    }

    &__value {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      min-height: 22px;
      margin-bottom: 7px;
      color: #333333;
    }
  }
}
