.divButtonReviewPrompt {
  position: absolute;
  right: 25px;
}
.buttonReviewPrompt {
  background: #0c8aff;
  width: 132px;
  height: 34px;
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  border: 1px solid;
  font-size: 11px;
}
.resultLeaderTestPass {
  background: #2ecc71;
  border-radius: 0px;
  width: 89px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.resultLeaderTestFail {
  background: rgba(231, 76, 60, 1);
  border-radius: 0px;
  width: 89px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
