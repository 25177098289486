@import 'styles/variable.scss';

.manager-order-leader {
  width: calc(100vw - 311px) !important;
  .wrapper-temp,
  .table-container {
    width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 30px;
      background-color: #c4c4c4;
    }
    .table-scroll {
      min-width: 1240px;
    }
    .div-temp {
      min-width: 1240px;
      height: 1px;
    }
  }
  .break-all {
    word-break: break-all;
  }
  .up-case {
    text-transform: uppercase;
  }
  .table-pagination {
    margin-bottom: 10px;
  }
  .header-content-left {
    padding-bottom: 15px;
    span {
      font-size: 18px;
    }
  }
  .info-menu-right-no-image {
    padding: 12px;
    .info-menu-righ-title {
      line-height: 16px;
    }
    .info-menu-righ-total {
      line-height: 16px;
    }
  }
  .title-mid-content {
    padding-top: 15px;
    margin-bottom: 10px;
    span {
      font-size: 16px;
    }
  }
  .text-level-leader {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mid-content {
    .MuiGrid-item {
      margin-top: 8px;
      &:nth-of-type(2n + 1) {
        color: #5a6882;
      }
      &.mid-content-item {
        color: #223354;
        font-weight: 600;
        &.status {
          color: #8d8d8d;
        }
      }
    }
  }
  .title-bottom-content span {
    color: #223354;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 15px;
    display: block;
  }
  .range-date-input {
    height: 50px;
    svg {
      font-size: 25px;
    }
  }
  .range-date-input {
    border: 1px solid #e7e7e7;
    height: 34px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    span {
      color: #bdbdbd;
    }
  }
  .filter {
    padding: 0 0 0 30px;
  }
  .filter-search {
    padding-top: 15px;

    .search-leader {
      background: #ffffff;
      border-radius: 5px;

      .MuiInputBase-input {
        color: #bdbdbd;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  .input-search-box {
    .MuiInputBase-input {
      color: rgb(158, 158, 158);
    }
    .MuiInputLabel-root {
      opacity: 0.38;
    }
  }
  .button-add-menu-right {
    @media screen and (max-width: 1120px) {
      font-size: 13px !important;
      padding: 11px 0;
      svg {
        font-size: 16px !important;
        margin-right: 2px !important;
      }
    }
  }
  table {
    th {
      color: $color-black;
      background-color: $color-headTable;
    }
    td {
      cursor: pointer;
      border-bottom: none;
    }
    .id-standard {
      color: $color-black !important;
    }
    tr.headTable {
      font-weight: 500;
      color: $color-black;
      background-color: $color-headTable !important;
    }
    tr:nth-child(odd) {
      background-color: $bg-white;
    }
    tr:nth-child(even) {
      background-color: $bg-tableStripped;
    }
  }
  .header-left {
    float: left;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #333333 !important;
  }
  .select-field {
    border-radius: 5px;
    padding: 15px;
    display: flex;
    position: relative;
    .selectFieldTitle {
      margin-left: 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $color-navActive;
      word-wrap: break-word;
    }
    .selectFieldTotal {
      position: absolute;
      right: 17px;
      color: $color-navActive;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .name-create {
    color: $color-navActive;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  .division {
    color: $color-titleExport;
  }
  .wait {
    color: $color-silver !important;
  }
  .error {
    color: $color-refuse !important;
  }
  .processing {
    color: $color-blue1 !important;
  }
  .lateDeadline {
    color: $bg-yellow1 !important;
  }
  .cancel {
    color: $color-cancel !important;
  }
  .completed {
    color: $color-active !important;
  }
  .same-color {
    color: $color-navActive;
  }
  .selected {
    background: $color-total-order-leader;
    color: $color-total-order;
    width: 100%;
  }
  .table-content {
    color: #333333;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  td.status-red {
    color: $color-red !important;
  }
  td.status-blue {
    color: $color-blue1 !important;
  }
  td.status-green {
    color: $color-status-blue !important;
  }
  .date-filter {
    input {
      font-size: 14px;
    }
    label {
      font-size: 15px;
      margin-top: -5px;
      color: rgba(0, 0, 0, 0.6);
    }
    .MuiFormControl-root {
      &:hover {
        label {
          color: $color-green;
        }
      }
    }
  }

  .reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 22px 32px;
    margin-top: 10px;
    height: 36px;
    background: $color-navActive;
    border-radius: 5px;
    color: $color-white;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    .img-reset {
      margin: '0 10px 0 0';
    }

    &:hover {
      background: $color-navActive;
      color: $color-white;
    }

    &:active {
      background: $color-blue !important;
      color: $color-white !important;
    }
  }
}
.detailOrder-leader-text-note p {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #223354;
  word-break: break-word;
}
.circle-assign-button {
  padding: 0px 3px 3px 3px;
  border-radius: 100%;
  vertical-align: middle;
  margin-right: 0;
  margin-top: -1px;
  width: 25px;
  height: 25px;
  border: 2px solid $color-borderTable;
  svg {
    color: $color-menuActive;
    font-size: 16px;
    margin-bottom: 2px;
  }

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}
.tooltip-assigner {
  color: #333333;
  background-color: #f3f7fb;
  height: 100%;
  padding: 11px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  border-radius: 4px;
  .text-division {
    padding: 4px 4px;
  }
}
.color-button {
  svg {
    color: $color-truncate !important;
  }
}
