@import './../../styles/variable.scss';

.modalPreview {
  z-index: 9999 !important;
  .linkDone {
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
    }
  }
  :global {
    .modal-footer {
      padding-top: 0px !important;
      border-top: none;
    }
  }
}

@media (min-width: 576px) {
  .modalPreview {
    :global {
      .modal-body {
        min-width: 380px;
        max-width: 900px;
      }
      .MuiPaper-root {
        max-width: max-content;
        margin: 0px;
      }
    }
  }
}

.modalPreviewImage {
  min-width: 380px;
  max-width: 900px;
  img {
    width: 100%;
  }
}
