@import 'styles/variable.scss';

.content-manager-candidate {
  .block {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    p {
      margin: 0;
    }
    span {
      font-weight: 600;
    }
    div {
      height: 110px;
      @media (min-width: 1240px) and (max-width: 1320px) {
        height: 120px;
      }
      border-radius: 5px;
    }
    .same-width {
      width: 17%;
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 4px;
      }
    }
    .block-total {
      width: 12%;
      padding: 12px;
      background: #ffdba9;
      color: #223354;
      p {
        font-weight: 500;
      }
      .title {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 10px;
      }
      .value {
        font-size: 30px;
        line-height: 22px;
        margin-top: 15px;
      }
    }
    .block-wait {
      background: $color-block-wait;
      padding: 8px;
      p {
        color: $color-blue1;
      }
    }
    .block-success {
      padding: 8px;
      background: $color-block-success;
      color: $color-active;
    }
    .block-refuse {
      padding: 8px;
      background: $color-block-refuse;
      color: $color-red;
    }
  }
  .filter-hr {
    padding: 10px 0 0 30px;
  }
  .filter-leader {
    padding: 10px 0 0 30px;
  }
  .table-scroll {
    overflow-x: scroll;
    cursor: pointer;
  }
  .scroll-custom {
    border-radius: 0%;
  }
  .scroll-custom::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }
  .scroll-custom::-webkit-scrollbar {
    width: 5px;
  }
  .scroll-custom::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $color-white;
  }
  .scroll-custom::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-navActive;
  }
  .wrapper-top {
    background-color: $bg-body;
    width: 100%;
    overflow-x: scroll;
    cursor: pointer;
    .top {
      width: 130%;
      margin-top: -25px;
    }
  }
  .wrapper-top {
    margin-top: -10px;

    p {
      font-size: 16px;
    }
  }
  .wrapper-top::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }
  .wrapper-top.thin::-webkit-scrollbar {
    width: 8px;
  }
  .wrapper-top::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $color-white;
  }
  .wrapper-top::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-navActive;
  }
  table {
    width: 130%;
    .checkbox-permanent {
      width: 1%;
      left: -1px;
      position: sticky;
      position: -webkit-sticky;
      z-index: 1;
    }
    th.checkbox-permanent {
      background-color: $color-headTable;
    }
    th {
      color: $color-black;
      .checkbox {
        cursor: pointer;
        margin: 0;
        margin-top: 8px;
        background-color: $color-white;
        font: caption;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid $color-silver;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
      }
    }
    td.status-red {
      color: $color-red !important;
    }
    td.status-blue {
      color: $color-blue-download !important;
    }
    td.status-green {
      color: $color-status-blue !important;
    }
    td {
      p {
        margin: 0;
        font-size: 14px;
      }
      .name {
        font-weight: 500;
        color: $color-navActive;
      }
      .email {
        font-size: 12px;
        font-weight: 500;
        color: $color-blue1;
        max-width: 240px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .checkbox {
        cursor: pointer;
        margin: 0;
        margin-top: 8px;
        background-color: $color-white;
        font: caption;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid $color-silver;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
      }
      .candidate-detail {
        text-decoration: none;
        color: $color-black !important;
        &:hover {
          color: $color-black !important;
        }
      }
    }
    .id-standard {
      color: $color-black !important;
    }
    tr.headTable {
      font-weight: 500;
      color: $color-black;
      background-color: $color-headTable !important;
    }
    tr:nth-child(odd) {
      background-color: $bg-white;
      td.checkbox-permanent {
        background-color: $bg-white;
      }
    }
    tr:nth-child(even) {
      background-color: $bg-tableStripped;
      td.checkbox-permanent {
        background-color: $bg-tableStripped;
      }
    }
  }
  .header-left {
    float: left;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }

  .color-text {
    color: #333333;
  }

  .select-field {
    border-radius: 5px;
    padding: 15px;
    display: flex;
    position: relative;
    .selectFieldTitle {
      margin-left: 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $color-navActive;
      word-wrap: break-word;
    }
    .selectFieldTotal {
      position: absolute;
      right: 17px;
      color: $color-navActive;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .selected {
    background: $color-totalEmployee;
  }

  .table-content {
    color: $color-titleBody !important;
    font-weight: 400;
    font-size: 14px;
  }

  .stt {
    text-align: center;
    width: 20px;
  }

  .field-cell-w100 {
    width: 100px;
  }

  .field-cell-w80 {
    width: 80px;
  }

  .field-cell-w50 {
    width: 50px;
  }

  .field-cell-w150 {
    width: 150px;
  }
  .text-division {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiTableCell-root {
    padding: 16px 10px;
  }

  .filter-search {
    .search {
      background: #ffffff;
      border-radius: 5px;

      .MuiInputBase-input {
        color: rgba(0, 0, 0, 0.38);
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        padding: 8.5px 10px 8.5px 0px !important;
      }
    }
  }

  .reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 22px 32px;
    margin-top: 10px;
    height: 36px;
    background: $color-navActive;
    border-radius: 5px;
    color: $color-white;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    .img-reset {
      margin: '0 10px 0 0';
    }

    &:hover {
      background: $color-navActive;
      color: $color-white;
    }

    &:active {
      background: $color-blue !important;
      color: $color-white !important;
    }
  }
  .button-compare {
    margin-top: 10px;
  }
  .button-assign {
    margin-top: 10px;
    background: #2ecc71;
  }
  .button-import {
    margin-top: 10px;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    svg {
      font-size: 30px;
    }
  }

  .btn-secondary.disabled {
    cursor: not-allowed;
    background: $color-download !important;
    color: $color-button-compare !important;
    opacity: 1;
    border: none;
  }

  .input-search-box {
    margin: 15px 0 0 0;
    .MuiInputLabel-root {
      opacity: 0.38;
      font-size: 15px;
    }
    .MuiInputBase-input {
      color: rgb(158, 158, 158);
    }
  }
  .date-search-box {
    margin: 15px 0 0 0;
    .MuiInputLabel-root {
      opacity: 0.34;
      font-size: 15px;
    }
    .MuiInputBase-input {
      color: #9f9b9bde;
    }
  }

  @media screen and (max-width: 1070px) {
    .button-add-menu-right {
      font-size: 13px;
    }
  }
}

.tooltip-division-order {
  color: #333333;
  background-color: #f3f7fb;
  width: 122px;
  height: 100%;
  padding: 11px 0 3px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  border-radius: 4px;
  .text-division {
    padding: 4px 0;
  }
}
.import-contract {
  .modal-title-head {
    color: #f9fbfc;
  }
  .MuiPaper-root {
    max-width: 500px !important;
    .modal-body {
      max-height: 400px;
      overflow: scroll;
      .import {
        margin-top: 0;
        .labelImport {
          width: -webkit-fill-available;
          position: relative;
          top: 0;
          left: 0;

          .imgImport {
            width: -webkit-fill-available;
            height: 100px;
            cursor: pointer;
          }
        }
      }
      .progress-percent {
        color: #8d8d8d;
        position: absolute;
        right: 6%;
        overflow: visible;
        height: 0px;
      }
      .error {
        color: #e74c3c !important;
        background: $bg-errorImport;
        border-radius: 5px;
        padding: 10px 35px 10px 15px;
        margin-top: 10px;
      }
      .fileInvalid {
        height: 15px;
        margin-right: 5px;
      }
      .infoImport {
        color: #333333;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 405px;
      }
      .progress-import {
        width: 100% !important;
        margin-right: 0 !important;
      }
      .info {
        position: absolute;
        z-index: 1;
        color: #8d8d8d;
        font-size: 14px;
      }
      .iconImport {
        height: 20px;
        width: 25px;
        left: 48%;
        top: 13px;
      }
      .notificationUpload {
        left: 38%;
        top: 40px;
      }
      .format {
        left: 39%;
        top: 60px;
      }
      .download-template {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        color: #0085ff;
        margin-bottom: 20px;
        margin-top: 20px;
        cursor: pointer;
      }
    }
    .modal-body::-webkit-scrollbar {
      width: 2px;
    }
    .modal-body.thin::-webkit-scrollbar {
      width: 2px;
    }
    .modal-body::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: $bg-yellow1;
    }
  }
}

.modal-create-candidate {
  .radio-label-container {
    .MuiFormControlLabel-label {
      font-size: 14px;
      color: #333333;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .text-modal {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    .info-cv-upload {
      display: flex;
      justify-content: flex-start;
      color: #575656;
      .cv-name {
        width: 90%;
        margin-left: 5px;
        .title {
          color: #333333;
          font-weight: 700;
          font-size: 16px;
          line-height: 23px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .loading-upload-cv {
      margin-top: 0;
      margin-bottom: 0;
      text-align: left;
    }
  }
  .label {
    color: #333333;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }
  .button-cancel {
    width: 100%;
  }
  .button-submit {
    width: 100%;
  }
  .upload-file-cv {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;

    .label-CV {
      color: #999999;
    }
  }
  .infor-basic {
    background: #f9f9f9;
    border-radius: 5px;
    p {
      margin: 0;
      padding: 10px 15px;
      span {
        font-weight: 700;
        font-size: 16px;
        color: #000000;
      }
    }
  }
  .link-to-detail {
    display: none;
  }
  .school {
    width: 100%;
    label {
      font-size: 14px;
    }
  }
}

.link-cv-input {
  position: relative;
  .error-input {
    position: absolute;
    top: 125px;
  }
}
