.container {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  .header {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    .titleHeader {
      color: #223354;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      margin-left: 8px;
    }
  }

  .itemContainer {
    display: flex;
    align-items: center;
    .titleItem {
      color: #5a6882;
      font-weight: 400;
      font-size: 14px;
    }
    .contentItem {
      color: #223354;
      font-weight: 700;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .backgroundHightLight {
    background: #f9f9f9;
    padding-left: 20px;
    padding-right: 20px;
    height: 65px;
    display: flex;
    align-items: center;
  }
  .rowContainer {
    padding-left: 20px;
    padding-right: 20px;
    min-height: 65px;
    display: flex;
    align-items: center;
  }
  .email {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #223354;
    font-weight: 700;
    font-size: 14px;
  }
  :global {
    .divider {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
