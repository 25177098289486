.btn-primary {
  background-color: black;
  border-color: black;
}

.button-add-menu-right{
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 11px;
  font-weight: 500;
  img {
    width: 20px;
    height: 20px;
  }

  color: $color-white;
  background-color: $bg-yellow1;
  border: 1px solid $color-yellow1;

  &:active,
  &:focus {
    background-color: $bg-yellow1 !important;
    border: 1px solid $color-yellow1 !important;
    color: $color-white !important;
    box-shadow: none;
  }

  &:hover:not(:disabled) {
    background-color: $bg-white;
    color: $color-yellow;
    border: 1px solid $color-yellow;
  }
}

.button-add-box,
.button-delete-box {
  width: 100%;
  background-color: $bg-white;
  color: $color-silver;
  font-size: 14px;
  line-height: 22px;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 11px;
  font-weight: 400;

  img {
    width: 20px;
    height: 20px;
  }
}

.button-add-box {
  border: 1px dashed $color-wheat;

  &:active,
  &:focus,
  &:hover {
    background-color: $bg-white !important;
    color: $color-silver !important;
    border: 1px solid $color-wheat;
    transform: translateY(-1px);
  }
}

.button-delete-box {
  border: 1px dashed $color-red;

  &:active,
  &:focus,
  &:hover {
    background-color: $bg-white !important;
    color: $color-silver !important;
    border: 1px solid $color-red;
    transform: translateY(-1px);
  }
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:active {
  box-shadow: none;
}
