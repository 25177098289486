@import './../../styles/variable.scss';

.inputGroup {
  position: relative;
  top: 0;
  left: 0;

  .image {
    position: absolute;
    top: 14px;
    right: 2px;
    height: 26px;
    background: white;
    padding-right: 5px;
    padding-left: 2px;

    .imgInputError {
      height: 18px;
      width: 20px;
    }
  }
}

.inputFiled {
  .notLabel {
    :global {
      .Mui-error {
        input {
          border: 1px solid $color-red !important;
        }
      }
    }
  }

  &:hover {
    :global {
      label {
        color: $color-green;
      }
      label.Mui-disabled {
        color: #00000061;
      }
      label.Mui-error {
        color: $color-red;
      }
    }
  }

  :global {
    .fa-li {
      position: initial;
    }
  }
}
