@import 'styles/variable.scss';

.content-standard-index {
  .title-header {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
  }
  .mt-10 {
    margin-top: 0.625rem;
  }
  .filter {
    padding-right: 0px;
    .button-create-standard {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      margin-bottom: 10px;
    }
    .info-menu-right-no-image {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
  table {
    th {
      color: $color-black;
    }
    td {
      cursor: pointer;
    }
    .id-standard {
      color: $color-black !important;
    }
    tr.headTable {
      font-weight: 500;
      color: $color-black;
      background-color: $color-headTable !important;
    }
    tr:nth-child(odd) {
      background-color: $bg-white;
    }
    tr:nth-child(even) {
      background-color: $bg-tableStripped;
    }
  }
  .select-field {
    border-radius: 5px;
    padding: 15px;
    display: flex;
    position: relative;
    .selectFieldTitle {
      margin-left: 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $color-navActive;
      word-wrap: break-word;
    }
    .selectFieldTotal {
      position: absolute;
      right: 17px;
      color: $color-navActive;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .selected {
    background: $color-totalEmployee;
    width: 100%;
  }

  .table-content {
    color: $color-titleBody !important;
    font-weight: 400;
    font-size: 14px;
  }

  .reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 22px 32px;
    margin-top: 10px;
    height: 36px;
    background: $color-navActive;
    border-radius: 5px;
    color: $color-white;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    .img-reset {
      margin: '0 10px 0 0';
    }

    &:hover {
      background: $color-navActive;
      color: $color-white;
    }

    &:active {
      background: $color-blue !important;
      color: $color-white !important;
    }
  }
}

.modal-create-update-standard-index {
  .text-modal {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $color-titleBody;
  }
  .avg-point {
    margin-left: 15px;
    .avg-point-title {
      color: #e74c3c;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
    .avg-point-content {
      color: $color-blueGray;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .display-flex {
    display: flex;
  }
  .delete-button {
    border-radius: 5px;
    padding: 3px;
    vertical-align: middle;
    float: left;
    width: 35px;
    height: 35px;
    background-color: $color-yellow1;
    border: none !important;
    margin: 0;
    float: right;
    margin-top: 7px;

    svg {
      color: $color-buttonSubmit;
      font-size: 29px;
    }

    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
  .add-button {
    padding: 3px;
    border-radius: 100%;
    vertical-align: middle;
    float: left;
    margin-right: 0;
    margin-top: 0px;
    width: 35px;
    height: 35px;
    border: 2px dashed $color-borderTable;
    margin: 16px 9px 16px 13px;

    svg {
      color: $color-menuActive;
      font-size: 26px;
    }

    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
  .add-master-stats {
    padding: 3px;
    border-radius: 100%;
    vertical-align: middle;
    float: left;
    margin-right: 0;
    margin-top: 0px;
    width: 35px;
    height: 35px;
    border: $color-borderTable;
    margin: 16px 0px 16px 13px;

    svg {
      color: #333333;
      font-size: 26px;
    }

    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
  .text-content-modal {
    font-weight: 400;
    font-size: 16px;
    color: $color-charcoal;
    line-height: 67px;
  }
  .top-list-index {
    border-bottom: 1px solid $color-borderTable;
    padding-top: 10px;
  }
  .text-modal-error {
    color: #e74c3c;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
  }
  .title-list-index {
    display: flex;
  }
  .button-cancel {
    float: right;
    width: 60%;
  }
  .button-submit {
    float: left;
    width: 60%;
  }
  .table-content {
    color: $color-titleBody !important;
    font-weight: 400;
    font-size: 14px;
    padding: 0 0 0 16px;
  }
  .table-text {
    color: $color-titleBody !important;
    font-weight: 500;
    font-size: 14px;
  }
  .information {
    border-bottom: 1px solid $color-borderTable;
  }
  .diagram-detail {
    display: flex;
    width: 100%;
    .edit-delete-button {
      padding: 3px;
      border-radius: 100%;
      vertical-align: middle;
      float: right;
      width: 35px;
      height: 35px;
      border: 2px solid $color-borderTable;
      margin-right: -30px;
      background: $color-button;

      svg {
        color: $color-menuActive;
        font-size: 26px;
      }

      &:hover {
        transform: scale(1.2);
        cursor: pointer;
      }
    }
    .color-button {
      svg {
        color: $color-truncate !important;
      }
    }
  }
  .error.custom {
    padding: 20px 0 !important;
  }
  .edit-delete-button {
    width: 40px;
    height: 41px;
    border: 1.5px dashed $color-borderTable;
  }
  .color-silver {
    svg {
      color: $color-silver !important;
    }
  }
  .list-standard-index {
    overflow: auto;
    overflow-x: hidden;
    max-height: 370px;
    width: 670px;
    padding-right: 10px;
    .row-standard-index {
      margin-bottom: 40px;
    }
  }
  .list-standard-index::-webkit-scrollbar {
    width: 4px;
  }
  .list-standard-index.thin::-webkit-scrollbar {
    width: 2px;
  }
  .list-standard-index::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-dataCV;
  }
}

.modal-detail-standard-index {
  .title-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #223354;
  }
  .title-item {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #223354;
  }
  .content-item {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #223354;
  }
  .edit-delete-button {
    border-radius: 100%;
    vertical-align: middle;
    float: right;
    width: 25px;
    height: 25px;
    border: 2px solid $color-borderTable;
    margin-right: -30px;
    background: $color-button;

    svg {
      font-size: 20px;
    }

    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
}
