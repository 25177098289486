@import 'styles/variable.scss';

.tableContainer {
  max-height: 330px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: #c4c4c4;
  }
  :global {
    .link-detail-UV {
      text-decoration: underline !important;
    }
  }
}

.alertAfterSubmit {
  p {
    width: 240px;
    color: rgba(0, 0, 0, 0.6);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

.spaceDiv {
  height: 38px;
}

.popupApproveCandidate {
  :global {
    .MuiDialog-paper {
      max-width: 524px;
    }
  }
}

.createRequestButton {
  background-color: #fea628 !important;
  border-radius: 5px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  height: 36px;
  width: 100%;
  padding: 7px 50px !important;
  border: 1px solid #fea628 !important;
  margin-bottom: 12px;
  &:hover {
    background-color: #f7fafc !important;
    color: #fea628 !important;
  }
}

.popupBtn {
  text-transform: uppercase;
  font-size: 14px !important;
  background-color: transparent !important;
  border: transparent !important;
  &__cancel {
    color: #8d8d8d !important;
    font-weight: 400 !important;
  }
  &__ok {
    color: #fea628 !important;
    font-weight: 500 !important;
  }
}

.modalCommon {
  max-width: 1100px !important;
  :global {
    .modal-content {
      border-radius: 10px !important;
    }
  }
  .modalTitle {
    background: $color-titleModal;
    border-radius: 5px 5px 0px 0px;
    width: auto;
    height: 50px;
    h5 {
      color: $color-white;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 21px;
      margin: -5px 0 0 0;
    }
    span {
      height: 30px;
      width: 30px;
      color: #fea628;
      border: 1px solid #223354;
      box-sizing: border-box;
      text-align: center;
      padding-top: 5px;
      margin-top: -12px;
      padding-top: 2px;
      border-radius: 50%;
      display: inline-block;
    }
  }

  .modalBody {
    padding: 0;
    .cancelButton {
      background-color: $color-refuse;
      border: 1px solid #ffffff;
      border-radius: 5px;
    }
    .completeButton {
      background: $color-status-blue;
      border-radius: 5px;
      border: 1px solid #ffffff;
    }
    .confirmBtn {
      background: $color-status-blue;
      border-radius: 5px;
      border: 1px solid $color-status-blue;
      width: max-content;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }
    .mainContent {
      padding-right: 15px;

      .orderName {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: #333333;
        word-break: break-word;
        max-width: 420px;
      }

      .mainInfo {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #223354;
      }

      .candidateInfo {
        margin-top: 15px;
        margin-bottom: 15px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #223354;
      }

      .titleItem {
        margin-bottom: 15px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #5a6882;
      }

      .contentItem {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #223354;
        word-break: break-word;
      }
      .titleAssignerName {
        white-space: nowrap;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pointerAssigner {
        cursor: pointer;
      }
    }
    .subContent {
      margin-top: -16px;
      padding-top: 20px;
      margin-bottom: -23px;
      padding-left: 20px;
      border-left: 1px solid #e7e7e7;
      .subContentTop {
        margin-bottom: 15px;
      }
      .subTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #223354;
        display: block;
        margin-bottom: 5px;
        margin-top: 15px;
        margin-top: 20px;
      }
      .timeLine {
        margin-left: 10px;
        max-height: 500px;
        overflow-y: scroll;
        padding: 0;

        .timelineDot {
          background-color: white !important;
          box-shadow: none;
          margin: 0;
          padding: 0;
          border: 0;
        }

        .connector {
          width: 1px;
          color: #e7e7e7;
        }

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 30px;
          background-color: #c4c4c4;
        }

        .timelineContent {
          padding: 0px 16px 15px 16px !important;
        }

        .timeCreate {
          display: block;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #8d8d8d;
        }
        .action {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #223354;
          display: block;
          margin-top: 5px;
        }
        .noteReason {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #515151;
          line-height: 18px;
          display: block;
          margin-top: 3px;
          word-break: break-word;
        }
      }
    }
    .itemCheck {
      width: -webkit-fill-available;
      cursor: pointer;
      padding: 18px;
      .checked {
        position: absolute;
        right: 30px;
        width: 18px;
      }

      .dragIcon {
        position: absolute;
        left: 15px;
      }

      .dragTitle {
        padding-left: 35px;
      }
      .checkFieldExport {
        display: none;
      }
      p.exportTitle {
        color: $color-titleExport;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 0 0;
      }
    }
    .activeExport {
      background: $color-exportActive;
    }
    .hrModal {
      margin: 0 auto;
      width: 93%;
      background: $bg-body;
      mix-blend-mode: normal;
    }
  }
  .modalFooter {
    justify-content: center;
    .buttonSubmit {
      width: -webkit-fill-available;
      background: $color-buttonSubmit;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      height: 36px;
      color: $bg-body;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      border: 1px solid $bg-yellow1;
      &:active {
        background-color: $color-buttonSubmitActive !important;
        color: $bg-body !important;
      }
      &:hover {
        color: $bg-body;
        background-color: $bg-white;
        color: $bg-yellow1;
        border: 1px solid $bg-yellow1;
      }
    }
  }
}

.modalAssignOrder {
  :global {
    .MuiPaper-root {
      width: 500px !important;
    }
  }
  .modalFooter {
    background-color: white;
    gap: 15px;
    border-top: none !important;
    padding: 0 1.25rem 1.25rem 1.25rem !important;

    .btn {
      flex-grow: 1;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      height: 36px;
      box-sizing: border-box;
      border: none;
      border-radius: 5px;
      min-width: min-content;
      width: 150px;

      &__action {
        color: white;
        background-color: var(--theme-main-color);
        &:hover:enabled {
        color: var(--theme-main-color);
        border: 1px solid var(--theme-main-color);
        background-color: #fff8ee !important;
      }
      }
      &__danger {
        color: white;
        background-color: #e74c3c;
      }
      &__cancel {
        color: var(--theme-main-color);
        background-color: #fff8ee;
      }
      &__disabled {
        color: white;
        background-color: lightgray;
        cursor: default;
      }
    }
  }
}

.loadingIcon {
  margin-right: 40px;
  height: 28px;
}
