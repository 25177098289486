@-webkit-keyframes circular {
  from {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
}
/* Standard syntax */
@keyframes circular {
  from {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
}
.bodyTest {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  background: #ffffff;
  margin-top: 30px;
  padding: 15px 25px 25px;
}
.containerInfoGeneral {
  margin-bottom: 30px;
}
.titleInformation {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 20px;
}
.leaderMarksContainer {
  margin: 0px;
  margin-bottom: 30px;
}
.homeworkTime {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
}
.linkTestTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  margin-top: 20px;
}

.timeTest {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  margin-right: 50px;
}

.linkTest {
  font-weight: 500;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 40px;
  background: #e4f5ff;
  border-radius: 4px;
  padding: 5px 12px;
}

.groupButtonLinkTest {
  margin-left: 10px;
  .buttonLinkTest {
    background-color: #2d99ff;
    color: white;
    font-size: 12px;
    line-height: 24px;
    margin-top: 20px;
    padding: 3px;
    margin-left: 20px;
    padding-left: 10px;
    border: 1px solid #2d99ff;
    &:hover {
      border: 1px solid #e4f5ff;
      background-color: #2d99ff;
      color: #ffffff;
    }
  }
  .buttonSyncActive {
    background-color: #fea628;
    border: 1px solid #fea628 !important;
    color: white !important;
  }
  .iconSyncActive {
    animation: circular 1s linear 0s infinite;
    -webkit-animation: circular 1s linear 0s infinite;
    -moz-animation: circular 1s linear 0s infinite;
    -o-animation: circular 1s linear 0s infinite;
  }
}

.formbody {
  display: flex;
  align-items: center;
}
.imageQuestion {
  width: 317px;
  height: 229.98px;
}
.bodyInfomationTest {
  padding-left: 15px;
  :global {
    .divider {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}
.question {
  color: #223354;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}
.contentQuestion {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.answer {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #223354;
  margin-bottom: 10px;
  margin-top: 10px;
}
.answerContent {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  word-wrap: break-word;
  word-break: break-word;
  max-height: 300px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 30px;
  }
}
.listBoxAnswer {
  margin-top: 10px;
  max-height: 350px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 30px;
  }
}
.icon {
  margin: 5px;
}
.imgPreviewGroup {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .imgPreview {
    overflow-y: hidden;
    height: 200px;
    width: 264px;
    margin-left: 5px;

    img {
      width: 100%;
      object-fit: cover;
      margin: auto;
      display: block;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
