.divDetailLeader {
  background-color: rgba(65, 255, 62, 0.05);
  padding-bottom: 10px;
}
.divDetailLeaderFail {
  background-color: rgba(255, 62, 62, 0.05);
}

.detailLeaderPass {
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  position: relative;
}
.noteAssessContainer {
  width: 300px;
  background-color: white;
  padding: 13px;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  border-radius: 4px;

  .title {
    color: #333333;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
  }

  .content {
    color: #333333;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}
.leaderRatePass {
  position: absolute;
  top: 0;
  right: 0;
  background: #2ecc71;
  border-radius: 0px 5px;
  color: white;
  width: 85px;
  height: 26px;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 2px;
  justify-content: center;
}
.avgScoreTitle {
  font-size: 18px;
  color: rgba(34, 51, 84, 1);
  font-weight: 700;
}
.avgScorePass {
  font-size: 18px;
  color: rgba(34, 51, 84, 1);
  font-weight: 700;
}
.contentScore {
  padding-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  border-top: 1px solid #e9ebee;
}
.levelAssess {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #526487;
  text-align: end;
}
.scoreAvg {
  display: flex;
  text-align: center;
  padding-top: 4px;
  padding-left: 15px;
  :global {
    .avg-score {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #526487;
    }
    .avg-score-stand {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #fea628;
    }
  }
}
.avatarLeaderPass {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 15px;
  margin-left: 20px;
}
.bodyDetailLeader {
  margin-top: 20px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  margin-bottom: 10px;
  padding-bottom: 20px;
}
.styleDateAssess {
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  position: absolute;
  right: 12%;
  top: 12px;
}
.containerNoteAssess {
  padding: 10px 20px 0px 20px;
  .generalAssessment {
    font-size: 16px;
    font-weight: 700;
    color: #333333;
    margin-right: 10px;
  }
  .contentGeneralAssessment {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    word-break: break-word;
  }

}

.boxInfoHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .boxInfoUser {
    display: flex;
    justify-content: space-between;
  }
}

.boxInfoAccess {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.leaderRateFail {
  position: absolute;
  top: 0;
  right: 0;
  background: #ff3e3e;
  border-radius: 0px 5px;
  color: white;
  width: 85px;
  height: 26px;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 2px;
  justify-content: center;
}

.standardIndexContainer {
  display: flex;
  align-items: center;
  .groupStandardIndex {
    width: 100%;
    padding-right: 50px;
  }
  .nameStandardIndex {
    span {
      margin-right: 10px;
    }
    .noteAssessBtn {
      cursor: pointer;
    }
  }
}
