@import 'styles/variable.scss';

.content {
  .header-left {
    float: left;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }
  .color-text {
    color: $color-charcoal;
  }
  .box-info {
    width: 100%;
    background-color: $bg-tooltip;
    height: auto;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;
    margin-right: 1.25%;
    margin-bottom: 10px;
    .title-box {
      padding: 16px 25px 16px 25px;
    }
    .text-title {
      font-weight: 500;
      font-size: 20px;
    }
    .border-bottom-detail {
      border: 1px solid $color-borderTable;
      margin: 0 10px;
    }
    .table-area {
      margin: 9px;
      .text-table-title {
        font-weight: 500;
        font-size: 14px;
      }
      .w-300 {
        width: 300px !important;
      }
      .w-150 {
        width: 150px !important;
      }
      .text-table-element {
        font-weight: 400;
        font-size: 14px;
        word-break: break-word;
      }
    }
  }
  .edit-delete-button {
    padding: 3px;
    border-radius: 100%;
    vertical-align: middle;
    float: right;
    margin-right: 0;
    margin-top: 0px;
    width: 35px;
    height: 35px;
    border: 2px dashed $color-borderTable;
    margin: 16px 9px 16px 0;

    svg {
      color: $color-menuActive;
      font-size: 26px;
    }

    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
  .circle-delete-button {
    padding: 0px 3px 3px 3px;
    border-radius: 100%;
    vertical-align: middle;
    float: right;
    margin-right: 0;
    margin-top: 0px;
    width: 25px;
    height: 25px;
    border: 2px solid $color-borderTable;
    margin: 16px 9px 16px 0;

    svg {
      color: $color-menuActive;
      font-size: 16px;
    }

    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
  .color-button {
    svg {
      color: $color-truncate !important;
    }
  }
}

.modal-add-bonus {
  .modal-title-head {
    color: #f8f8f8;
  }
  #mui-component-select-type,
  #mui-component-select-milestone {
    color: #000000;
  }
  .MuiPaper-root {
    max-width: 468px !important;
  }
}

.add-button {
  padding: 3px;
  border-radius: 100%;
  vertical-align: middle;
  float: left;
  margin-right: 0;
  margin-top: 0px;
  width: 35px;
  height: 35px;
  border: 2px dashed $color-borderTable;
  margin: 16px 9px 16px 13px;

  svg {
    color: $color-menuActive;
    font-size: 26px;
  }

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}

.add-time-bonus {
  padding-bottom: 15px;
  border-bottom: 1.5px solid $color-borderTable;
  margin: 0 2px !important;
  .error-input {
    min-width: 200px;
  }
}
.mt-text {
  margin-top: 10px !important;
}

.delete-button {
  padding: 0px 3px 3px 3px;
  border-radius: 100%;
  vertical-align: middle;
  float: left;
  width: 25px;
  height: 25px;
  border: 2px solid $color-borderTable !important;
  float: right;

  svg {
    color: $color-truncate;
    font-size: 16px;
  }

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}

.input-field {
  padding-bottom: 17px;
  border-bottom: 1.5px $color-borderTable;
}

.ml--15 {
  margin-left: -15px !important;
}
.text-timeAfterOnboard {
  text-align: center;
  color: #000000 !important;
}
.text-chargeOfficial {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding-top: 10px;
}

.text-content-modal {
  font-weight: 400;
  font-size: 16px;
  color: $color-charcoal;
  line-height: 67px;
}

.text-title-modal {
  font-weight: 500;
  font-size: 16px;
  color: $color-titleBody;
  width: 100%;
  margin-top: 10px;
}

.no-padding {
  padding: 0 !important;
}

.outline-select-input {
  .MuiOutlinedInput-notchedOutline legend {
    width: 0;
  }
}

.error-no-data {
  margin: auto;
  color: $color-error;
  font-weight: 500;
  font-size: 16px;
}

.loading {
  margin: auto;
}
