@import './../../styles/variable.scss';
.ctButton {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: $color-white;
  background-color: $bg-yellow1;
  border-radius: 5px;
  padding: 7px 32px;
  box-shadow: none;
  border: 1px solid $color-yellow1;
  white-space: nowrap;

  &:active,
  &:focus {
    background-color: $bg-yellow1 !important;
    border: 1px solid $color-yellow1 !important;
    color: $color-white !important;
    box-shadow: none;
  }

  &:hover {
    background-color: $bg-white;
    color: $color-yellow;
    border: 1px solid $color-yellow;
  }

  img {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
  :global {
    svg {
      font-size: 20px;
      margin-right: 5px;
    }
  }
}

:global {
  .btn-secondary.disabled {
    cursor: default;
    transform: translateY(0);
    background-color: $bg-yellow1 !important;
    color: $color-white;
    border-color: $bg-yellow1 !important;
  }
}

.iconLoading {
  font-size: 14px;
  margin-left: 10px;
}
