@import 'styles/variable.scss';

.cardWrapper {
  display: flex;
  align-items: center;
  margin-top: 5px;

  .avatar {
    margin-right: 10px;
    width: 38px;
    height: 38px;
  }
  .name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #223354;
    display: block;
    margin-bottom: 5px;
  }
  .position {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #8D8D8D;
  }
}
