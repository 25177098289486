@import './../../styles/variable.scss';
.dateTime {
  :global {
    .input-group-text {
      border-top-right-radius: 0.375rem !important;
      border-bottom-right-radius: 0.375rem !important;
    }

    // .input-group-prepend {
    //   &:hover {
    //     cursor: pointer;
    //   }
    // }
  }
  img {
    width: 20px;
  }
}

.noLabel {
  legend {
    padding: 0;
    float: left;
    min-width: 0;
  }
  .MuiOutlinedInput-input {
    padding: 6px 0px 10px 15px !important;
  }
}

.openCalenderTime {
  :global {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-notchedOutline {
        border-color: $color-green !important;
      }
      .MuiSvgIcon-root {
        color: $color-green !important;
      }
    }
    .MuiInputLabel-root {
      color: $color-green !important;
    }
    .MuiInputLabel-root.Mui-error {
      color: $color-red !important;
    }
    .Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: $color-red !important;
      }
      .MuiSvgIcon-root {
        color: $color-red !important;
      }
    }
  }
}

.datePicker {
  height: 38px;
  width: -webkit-fill-available;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  padding: 0 0 0 10px;
  &:focus,
  &:hover {
    border: 1px solid $color-green;
  }
}

.datePicker:focus,
.datePicker:focus:not(:focus-visible) {
  outline: 0;
}

.selectTime {
  padding: 10px 15px !important;
  font-size: 14px;
  width: 100%;
  border-radius: 5px !important;
  height: 45px;
  :global {
    .ant-picker-input {
      position: relative;
    }
    .ant-picker-suffix {
      position: absolute;
      right: 0;
    }
  }
}
.selectTime:hover {
  border-color: #66d997 !important;
}
.selectTimeError {
  padding: 10px 15px !important;
  font-size: 14px;
  width: 100%;
  border-radius: 5px !important;
  border-color: red !important;
  height: 44px;
  :global {
    .ant-picker-input {
      position: relative;
    }
    .ant-picker-suffix {
      position: absolute;
      right: 0;
    }
  }
}
.inputTime {
  position: absolute !important;
}

:global {
  .ant-picker-dropdown {
    z-index: 10000 !important;
  }
  .ant-picker-focused {
    border-color: #66d997 !important;
    box-shadow: none !important;
    border-width: 1px !important;
  }
  .ant-picker-footer {
    display: none !important;
  }
}
