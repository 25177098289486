.container {
  .rowInList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #5a6882;
      padding-right: 10px;
    }
    .value {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #223354;
    }
  }
  .rowJob {
    padding: 16px 30px !important;
  }
  .borderJob {
    border-left: 1px solid #8d8d8d;
  }
}
