@import "../../../../styles/variable.scss";

.createBtn {
  background-color: $color-yellow  !important;
  color: $color-white  !important;
  text-transform: none !important;
  border: 1px solid $color-yellow  !important;
  border-radius: 5px !important;
  width: 100%;
  height: 36px;
  margin-bottom: 1rem !important;

  &:hover {
    background-color: $color-white  !important;
    color: $color-yellow  !important;
  }
}

.resetBtn {
  background-color: #223354 !important;
  color: $color-white  !important;
  text-transform: none !important;
  border: 1px solid #223354 !important;
  border-radius: 5px !important;
  width: 100%;
  height: 36px;
  margin-top: 1rem !important;

  img {
    width: 22px;
  }
}

.total {
  background-color: $color-total-order-leader;
  height: 40px;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  color: #333333;
}

.inputField {
  border: 1px solid $color-inputColor;
  border-radius: 5px;
  width: 100%;
  padding: 0 14px;
  
  &>input {
    cursor: pointer;
    font-size: 14px;
    height: 34px;
    padding: 0;
  }
}

.modalFooter {
  background-color: white;
  gap: 15px;
  border-top: none !important;
  padding: 0 1.25rem 1.25rem 1.25rem !important;

  .selectBtn {
    background-color: $color-yellow  !important;
    color: $color-white  !important;
    text-transform: none !important;
    border: 1px solid $color-yellow  !important;
    border-radius: 5px !important;
    width: 100%;
    height: 36px;

    &:hover {
      background-color: $color-white  !important;
      color: $color-yellow  !important;
    }
  }
}

.selectionModal {
  :global {
    .modal-body {
      padding: 1rem 0;
    }
  }

  .selectOptions {
    list-style: none;
    padding: 0;

    &>li {
      cursor: pointer;

      &:hover {
        background-color: $color-yellow1;
      }

      &>p {
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 40px;
        margin: 0;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;

        &>:nth-child(1) {
          margin-left: 13px;
        }

        &>:nth-child(2) {
          color: $color-yellow;
          width: 18px;
          object-fit: contain;
        }
      }

      &.selected {
        background-color: $color-total-order-leader;
      }

      .divider {
        display: flex;
        padding: 0 20px;

        &>hr {
          width: 100%;
          border-color: #E7E7E7;
        }
      }
    }
  }
}