.historyContainer {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  margin-top: 20px;
  padding-top: 25px;
  padding-left: 20px;

  .titleTimeline {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #223354;
  }

  .actionName {
    color: #223354;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  .reasonContent {
    color: #223354;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    word-break: break-word;
    max-width: 380px;
  }
  .leaderName {
    position: absolute;
    top: -8px;
    background: white;
    font-size: 14px;
    color: #223344;
    padding: 2px 4px;
    line-height: 15px;
    font-weight: 500;
  }
}
