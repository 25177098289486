@import './../../styles/variable.scss';
.headModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-white;
  background: #222433;
  font-weight: 500;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 5px 5px 0px 0px;

  .iconClose {
    width: 30px;
    height: 30px;
    border: 1px solid #223354;
    box-sizing: border-box;
    border-radius: 50%;
    font-size: 19px;
    padding-left: 5px;
    color: #fea628;
    display: flex;
    align-items: center;

    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
}
.modalCommon {
  :global {
    .modal-content {
      border-radius: 5px !important;
    }
  }
  .modalTitle {
    background: $color-titleModal;
    border-radius: 5px 5px 0px 0px;
    width: auto;
    height: 50px;
    h5 {
      color: $bg-body;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 21px;
      margin: -5px 0 0 0;
    }
    span {
      height: 30px;
      width: 30px;
      color: #fea628;
      border: 1px solid #223354;
      box-sizing: border-box;
      text-align: center;
      padding-top: 5px;
      margin-top: -12px;
      padding-top: 2px;
      border-radius: 50%;
      display: inline-block;
    }
  }

  .modalBody {
    padding: 0;
    max-height: 65vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 30px;
      background-color: #c4c4c4;
    }

    .searchBar {
      margin: 23px 20px 20px 23px;
      width: 438px;
      height: 34px;
    }
    .itemCheck {
      width: -webkit-fill-available;
      cursor: pointer;
      padding: 18px;
      .checked {
        position: absolute;
        right: 30px;
        width: 18px;
      }

      .dragIcon {
        position: absolute;
        left: 15px;
      }

      .dragTitle {
        padding-left: 35px;
      }
      .checkFieldExport {
        display: none;
      }
      p.exportTitle {
        color: $color-titleExport;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 0 0;
      }
    }
    .activeExport {
      background: $color-exportActive;
    }
    .hrModal {
      margin: 0 auto;
      width: 93%;
      background: $bg-body;
      mix-blend-mode: normal;
    }
  }
  .modalFooter {
    justify-content: center;
    .buttonSubmit {
      width: -webkit-fill-available;
      background: $color-buttonSubmit;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      height: 36px;
      color: $bg-body;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      border: 1px solid $bg-yellow1;
      &:active {
        background-color: $color-buttonSubmitActive !important;
        color: $bg-body !important;
      }
      &:hover {
        color: $bg-body;
        background-color: $bg-white;
        color: $bg-yellow1;
        border: 1px solid $bg-yellow1;
      }
    }
  }
}
