.modalFooter {
  background-color: white;
  gap: 15px;
  border-top: none !important;
  padding: 0 1.25rem 1.25rem 1.25rem !important;

  .btn {
    flex-grow: 1;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    height: 36px;
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    min-width: min-content;
    width: 150px;

    &__action {
      color: white;
      background-color: var(--theme-main-color);
      &:hover:enabled {
        color: var(--theme-main-color);
        border: 1px solid var(--theme-main-color);
        background-color: #fff8ee !important;
      }
    }
    &__danger {
      color: white;
      background-color: #e74c3c;
    }
    &__cancel {
      color: var(--theme-main-color);
      background-color: #fff8ee;
    }
    &__disabled {
      color: white;
      background-color: lightgray;
      cursor: default;
    }
  }
}

.modalExport {
  .formSelect {
    :global {
      .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #66d997 !important;
      }
      .Mui-focused {
        color: #66d997 !important;
      }
      .MuiInputBase-input {
        color: rgba(0, 0, 0, 0.87) !important;
      }
      .MuiSelect-icon {
        font-size: 2.25rem;
      }
      .Mui-focused .MuiSvgIcon-root {
        color: #66d997 !important;
      }
    }
    &:hover {
      :global {
        .MuiSvgIcon-root {
          color: #66d997 !important;
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: #66d997 !important;
        }
        label {
          color: #66d997 !important;
        }
      }
    }
  }
}
