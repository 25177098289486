@import 'styles/variable.scss';

.list-JD {
  table {
    th {
      color: $color-black;
      padding: 16px !important;
    }
    td {
      cursor: pointer;
    }
    .id-standard {
      color: $color-black !important;
    }
    .thead {
      background-color: $color-headTable;

      .profile-content-info {
        color: $color-titleBody;
      }
    }

    tr {
      font-weight: 500;
      color: $color-charcoal;
      cursor: pointer;
    }
    tr.headTable {
      font-weight: 500;
      color: $color-black;
      background-color: $color-headTable !important;
    }
    tr:nth-child(odd) {
      background-color: $bg-white;
    }
    tr:nth-child(even) {
      background-color: $bg-tableStripped;
    }
  }
  .header-left {
    float: left;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    padding: 0px !important;
  }
  .table-content {
    color: $color-titleBody !important;
    font-weight: 400;
    font-size: 14px;
  }
  @media only screen and (max-width: 1650px) {
    .table-scroll {
      overflow-x: scroll;

      width: 130%;
    }
  }
  .scroll-custom::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }
  .scroll-custom::-webkit-scrollbar {
    width: 2px;
  }
  .scroll-custom::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $color-white;
  }
  .scroll-custom::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-navActive;
  }
  .text-title-jd {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
    word-break: break-all;
    font-weight: 500 !important;
  }
  .filter {
    padding: 0px;
    .button-add-menu-right {
      height: 38px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .info-menu-right-no-image {
      padding-top: 0px;
      padding-bottom: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
    }
  }
  .reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 22px 32px;
    height: 36px;
    background: $color-navActive;
    border-radius: 5px;
    color: $color-white;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    .img-reset {
      margin: '0 10px 0 0';
    }

    &:hover {
      background: $color-navActive;
      color: $color-white;
    }

    &:active {
      background: $color-blue !important;
      color: $color-white !important;
    }
  }
  .input-search-box {
    input {
      font-size: 14px;
    }
    label {
      font-size: 15px;
      margin-top: -5px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .date-search-box {
    margin: 15px 0 0 0;
    .MuiInputLabel-root {
      opacity: 0.34;
      font-size: 15px;
    }
    .MuiInputBase-input {
      color: #9f9b9bde;
    }
  }
}

.modal-create-update-JD {
  .MuiPaper-root {
    max-height: 90vh !important;
  }
  .body-custom {
    padding: 0px !important;
  }
  .ql-container.ql-snow {
    min-height: 130px !important;
    max-height: 400px !important;
    overflow-y: scroll !important;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #c4c4c4;
    }
  }
  .background-header {
    background-color: $bg-nav;
  }
  .text-header-create-JD {
    padding-top: 0px !important;
    font-size: 20px !important;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 23px;
    color: $color-navy;
    width: 50%;
    word-break: break-all;
  }
  .header {
    height: 100%;
    background: $bg-salmon;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 12px 18px;
  }
  .header-left {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
  }
  .button-cancel-custom {
    margin-right: 20px;
    width: 92px;
    height: 36px;
    text-align: center;
    padding: 0.375rem 0.75rem !important;
  }
  .button-edit-custom {
    width: 92px;
    text-align: center;
    height: 36px;
    padding: 0.375rem 0.75rem !important;
  }
  .button-submit-custom {
    width: 92px;
    height: 36px;
    text-align: center;
  }
  .modal-body-custom {
    padding: 1.5rem;
  }
  .modal-radio-managerJD {
    padding-left: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    color: #000000 !important;
  }
  .modal-status {
    margin-top: 30px;
  }
  .modal-status-custom {
    margin-right: 153px;
  }
  .text-status {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  .mb-10 {
    margin-bottom: 13px;
  }
  .text-header-body-modal {
    color: #223354;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .text-detail-data-jd {
    color: #223354;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .information {
    border-bottom: 1px solid $color-borderTable;
  }
  .button-download-custom {
    margin-right: 20px;
    width: 92px;
    height: 36px;
    padding: 7px 23px;
    background-color: $color-download;
    color: $color-blue-download;
    border: 1px solid $color-download;

    &:focus {
      background-color: $color-blue-download !important;
      border: 1px solid $color-download !important;
      color: $color-white !important;
      box-shadow: none;
    }

    &:hover {
      background-color: $color-blue-download;
      color: $bg-white;
      border: 1px solid $color-download;
    }
  }
}
