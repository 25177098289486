.base-breadcrumb {
  background: transparent !important;
  li {
    span,
    a {
      color: #222222;
    }
    a {
      color: #8D8D8D;
    }
    span {
      margin: 0px 5px;
      color: #8D8D8D;
    }
  }
  li:last-child span {
    display: none;
  }

  .link-breadcrumb {
    color: #8D8D8D;
    &:hover {
      cursor: pointer;
    }
  }
}
