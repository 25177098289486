.div-leader-evaluate-detail-apply {
  border: 1px solid #e4f5ff;
  background: #e4f5ff;
  width: 100%;
  position: relative;
  margin-top: 10px;
  padding: 9px 0px 11px 25px;

  .div-name-leader {
    font-size: 14px;
    font-family: Roboto;
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
  }
  .div-avatar-leader {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
  .div-name-division {
    font-size: 12px;
    font-family: Roboto;
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
  }
  .avatar-leader-notRate {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 15px;
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    border-color: #d9d9d9;
    background: rgba(12, 138, 255, 0.08);
    text-shadow: none;
    box-shadow: none;
    color: rgba(255, 255, 255, 1);
  }
}
