.container {
  .headerPage {
    display: flex;
    justify-content: space-between;
    .titleHeader {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #333333;
      text-transform: uppercase;
    }
    .groupBtnHeader {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .buttonExport {
        padding: 7px 32px !important;
        background-color: #fea628;
        color: white;
        border-radius: 5px;
        border: 1px solid #fea628;
        height: 36px;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .orderAndLevelBlock {
    height: 440px;
  }
  .jobBlock {
    height: 123px;
  }
  .sourceCvBlock {
    height: 579px;
  }
  .lastBlock {
    margin-bottom: 50px;
  }
}
