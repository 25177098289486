@import './variable.scss';
@import './button.scss';
@import './styles.scss';
@import './_alert.scss';
@import './mui.scss';
@import './bootstrap.scss';

// check box
.ct-checkbox {
  .custom-control-input {
    &:checked {
      ~ {
        .custom-control-label {
          &::before {
            color: #fff;
            border-color: $bg-yellow1 !important;
            background-color: $bg-yellow1;
            box-shadow: none;
          }
        }
      }
    }
  }

  .custom-control {
    padding-left: 2rem;
  }
  .custom-control-label::before,
  .custom-control-label::after {
    left: -2rem;
  }
}

select {
  background: url('./../assets/img/icons/select.png') no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  background-size: 14px;
}

.swiper-slide {
  margin-right: 15px !important;
}

.table {
  .tableHeader {

  }

  .tableRow td {
    border-bottom: unset;
  }

  .tableRow:nth-child(odd) {
    background-color: $bg-white;
  }

  .tableRow:nth-child(even) {
    background-color: $bg-tableStripped;
  }
}

.date-picker-ant-custom {
  padding: 5px 11px;
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  &:hover {
    cursor: pointer;
    border-color: #2ecc71;
    .ant-picker-suffix {
      color: #2ecc71;
    }
  }
  .ant-picker-input > input {
    cursor: pointer;
  }
  .ant-picker-suffix {
    color: rgb(117, 117, 117);
  }
  .ant-picker-clear {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
