@import 'styles/variable.scss';

.manager-order-hr {
  width: calc(100vw - 311px) !important;
  .wrapper-temp,
  .table-container {
    width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 30px;
      background-color: #c4c4c4;
    }
    .table-scroll {
      min-width: 1240px;
    }
    .div-temp {
      min-width: 1240px;
      height: 1px;
    }
  }
  .up-case {
    text-transform: uppercase;
  }
  .filter {
    padding: 4px 0 0 30px;
  }
  .table-pagination {
    margin-bottom: 10px;
  }
  .info-menu-right-no-image {
    padding: 12px;
  }
  .filter-search {
    padding-top: 15px;

    .search-hr {
      background: #ffffff;
      border-radius: 5px;

      .MuiInputBase-input {
        color: rgba(0, 0, 0, 0.38);
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  .button-add-menu-right {
    @media screen and (max-width: 1120px) {
      font-size: 13px !important;
      padding: 11px 0;
      svg {
        font-size: 16px !important;
        margin-right: 2px !important;
      }
    }
  }
  table {
    th {
      color: $color-black;
      background-color: $color-headTable;
    }
    td {
      cursor: pointer;
      border-bottom: none;
    }
    .id-standard {
      color: $color-black !important;
    }
    tr.headTable {
      font-weight: 500;
      color: $color-black;
      background-color: $color-headTable !important;
    }
    tr:nth-child(odd) {
      background-color: $bg-white;
    }
    tr:nth-child(even) {
      background-color: $bg-tableStripped;
    }
  }
  .header-left {
    float: left;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #333333 !important;
  }
  .select-field {
    border-radius: 5px;
    padding: 15px;
    display: flex;
    position: relative;
    .selectFieldTitle {
      margin-left: 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $color-navActive;
      word-wrap: break-word;
    }
    .selectFieldTotal {
      position: absolute;
      right: 17px;
      color: $color-navActive;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .name-create {
    color: $color-navActive;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  .division {
    color: $color-titleExport;
  }
  .wait {
    color: $color-silver !important;
  }
  .error {
    color: $color-refuse !important;
  }
  .processing {
    color: $color-blue1 !important;
  }
  .lateDeadline {
    color: $bg-yellow1 !important;
  }
  .cancel {
    color: $color-cancel !important;
  }
  .completed {
    color: $color-active !important;
  }
  .same-color {
    color: $color-navActive;
  }
  .selected {
    background: $color-block-success;
    color: $color-total-order;
    width: 100%;
  }
  .table-content {
    color: #333333;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .range-date-input {
    height: 50px;
    svg {
      font-size: 25px;
    }
  }
  .range-date-input {
    border: 1px solid #e7e7e7;
    height: 34px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    span {
      color: #bdbdbd;
    }
  }
  .text-level-hr {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date-filter {
    input {
      font-size: 14px;
    }
    label {
      font-size: 15px;
      margin-top: -5px;
      color: rgba(0, 0, 0, 0.6);
    }
    .MuiFormControl-root {
      &:hover {
        label {
          color: $color-green;
        }
      }
    }
  }

  .input-search-box {
    .MuiInputBase-input {
      color: rgb(158, 158, 158);
    }
    .MuiInputLabel-root {
      opacity: 0.38;
    }
  }

  .division-filter-table {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 30px 20px;
    max-height: 500px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 30px;
      background-color: #c4c4c4;
    }

    .title-filter {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: $color-titleExport;
    }

    .MuiTypography-root {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $color-titleExport;
    }

    .row-division {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .radio {
        margin-bottom: 0;
      }
      .total-order {
        background: #fff8ee;
        border-radius: 5px;
        padding: 1px 8px;
        gap: 10px;
        color: #223354;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 22px 32px;
    margin-top: 10px;
    height: 36px;
    background: $color-navActive;
    border-radius: 5px;
    color: $color-white;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    .img-reset {
      margin: '0 10px 0 0';
    }

    &:hover {
      background: $color-navActive;
      color: $color-white;
    }

    &:active {
      background: $color-blue !important;
      color: $color-white !important;
    }
  }
}
.detailOrder-text-note p {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #223354;
  word-break: break-word;
}
