@import 'styles/variable.scss';

.listJobScroll {
  max-height: Min(400px, 40vh);
}

.tableContainer {
  max-height: 330px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: #c4c4c4;
  }
  :global {
    .link-detail-UV {
      text-decoration: underline !important;
    }
  }
}

.alertAfterSubmit {
  p {
    width: 240px;
    color: rgba(0, 0, 0, 0.6);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

.spaceDiv {
  height: 38px;
}

.modalFooter {
  background-color: white;
  gap: 15px;
  border-top: none !important;
  padding: 0 1.25rem 1.25rem 1.25rem !important;

  .btn {
    flex-grow: 1;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    height: 36px;
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    min-width: min-content;
    width: 150px;

    &__action {
      color: white;
      background-color: var(--theme-main-color);
      &:hover:enabled {
        color: var(--theme-main-color);
        border: 1px solid var(--theme-main-color);
        background-color: #fff8ee !important;
      }
    }
    &__danger {
      color: white;
      background-color: #e74c3c;
    }
    &__cancel {
      color: var(--theme-main-color);
      background-color: #fff8ee;
    }
    &__disabled {
      color: white;
      background-color: lightgray;
      cursor: default;
    }
  }
}
.modalAddJob {
  :global {
    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #66d997 !important;
    }
    .Mui-focused {
      color: #66d997 !important;
    }
    .MuiInputBase-input {
      color: rgba(0, 0, 0, 0.87) !important;
    }
  }
}
.errorMessage {
  font-size: 12px;
  line-height: 20px;
  color: #e74c3c;
  font-weight: 300;
  padding-left: 5px;
}

.titleTotalOrder {
  color: $color-total-order;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  word-wrap: break-word;
}

.totalOrder {
  position: absolute;
  color: $color-total-order;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  right: 17px;
}

.jobItem {
  text-transform: uppercase;
}

.modalCommon {
  max-width: 1100px !important;
  :global {
    .modal-content {
      border-radius: 10px !important;
    }
  }
  .modalTitle {
    background: $color-titleModal;
    border-radius: 5px 5px 0px 0px;
    width: auto;
    height: 50px;
    h5 {
      color: $color-white;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 21px;
      margin: -5px 0 0 0;
    }
    span {
      height: 30px;
      width: 30px;
      color: #fea628;
      border: 1px solid #223354;
      box-sizing: border-box;
      text-align: center;
      padding-top: 5px;
      margin-top: -12px;
      padding-top: 2px;
      border-radius: 50%;
      display: inline-block;
    }
  }

  .modalBody {
    padding: 0;
    max-height: 860px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
      border-radius: 30px;
    }
    .addJob {
      margin-right: 10px;
      background: $color-status-blue;
      border-radius: 5px;
      padding: 6px 16px;
      gap: 8px;
      border: 1px solid #ffffff;
    }
    .refuse {
      background-color: $color-refuse;
      border: 1px solid #ffffff;
      border-radius: 5px;
      padding: 6px 16px;
      gap: 8px;
    }
    .groupBtn {
      display: flex;
      .deleteButton {
        margin-left: 10px;
        background-color: $color-refuse;
        border: 1px solid #ffffff;
        border-radius: 5px;
        padding: 6px 16px;
        gap: 8px;
      }
      .cancelButton {
        background-color: $color-refuse;
        border: 1px solid #ffffff;
        border-radius: 5px;
      }
      .completeButton {
        margin-left: 10px;
        background: $color-status-blue;
        border-radius: 5px;
        border: 1px solid #ffffff;
      }
    }

    .mainContent {
      padding-right: 15px;

      .jobNameLine {
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #223354;
      }

      .orderName {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: #333333;
        word-break: break-word;
      }

      .wrapNameOrderNotInJob {
        max-width: 300px;
      }

      .wrapNameOrderInJob {
        max-width: 280px;
      }

      .wrapNameOrderInJobCantRemove {
        max-width: 415px;
      }

      .mainInfo {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #223354;
      }

      .candidateInfo {
        margin-top: 15px;
        margin-bottom: 15px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #223354;
      }

      .titleItem {
        margin-bottom: 15px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #5a6882;
      }

      .contentItem {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #223354;
        word-break: break-word;
        overflow-y: scroll;
        max-height: 150px;
        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 30px;
          background-color: #c4c4c4;
        }
      }
      .titleAssignerName {
        white-space: nowrap;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pointerAssigner {
        cursor: pointer;
      }
    }
    .subContent {
      margin-top: -20px;
      padding-top: 20px;
      margin-bottom: -23px;
      padding-left: 20px;
      border-left: 1px solid #e7e7e7;
      .subContentTop {
        margin-bottom: 15px;
      }
      .subTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #223354;
        display: block;
        margin-bottom: 5px;
        margin-top: 20px;
      }
      .timeLine {
        margin-left: 10px;
        max-height: 500px;
        overflow-y: scroll;
        padding: 0;

        .timelineDot {
          background-color: white !important;
          box-shadow: none;
          margin: 0;
          padding: 0;
          border: 0;
        }

        .connector {
          width: 1px;
          color: #e7e7e7;
        }

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 30px;
          background-color: #c4c4c4;
        }
        .timelineContent {
          padding: 0px 16px 15px 16px !important;
        }

        .timeCreate {
          display: block;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #8d8d8d;
        }
        .action {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #223354;
          display: block;
          margin-top: 5px;
        }
        .noteReason {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #515151;
          line-height: 18px;
          display: block;
          margin-top: 3px;
          word-break: break-word;
        }
      }
    }
    .itemCheck {
      width: -webkit-fill-available;
      cursor: pointer;
      padding: 18px;
      .checked {
        position: absolute;
        right: 30px;
        width: 18px;
      }

      .dragIcon {
        position: absolute;
        left: 15px;
      }

      .dragTitle {
        padding-left: 35px;
      }
      .checkFieldExport {
        display: none;
      }
      p.exportTitle {
        color: $color-titleExport;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 0 0;
      }
    }
    .activeExport {
      background: $color-exportActive;
    }
    .hrModal {
      margin: 0 auto;
      width: 93%;
      background: $bg-body;
      mix-blend-mode: normal;
    }
  }
  .modalFooter {
    justify-content: center;
    .buttonSubmit {
      width: -webkit-fill-available;
      background: $color-buttonSubmit;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      height: 36px;
      color: $bg-body;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      border: 1px solid $bg-yellow1;
      &:active {
        background-color: $color-buttonSubmitActive !important;
        color: $bg-body !important;
      }
      &:hover {
        color: $bg-body;
        background-color: $bg-white;
        color: $bg-yellow1;
        border: 1px solid $bg-yellow1;
      }
    }
  }
}
