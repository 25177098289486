.info-testing {
  .pl-15 {
    padding-left: 15px;
  }
  .pr-15 {
    padding-right: 15px;
  }
  .mt-70 {
    margin-top: 70px;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .mr-12 {
    margin-right: 12px;
  }
  .mr-30 {
    margin-right: 30px;
  }
  .pl-10 {
    padding-left: 10px;
  }
  .pt-10 {
    padding-top: 10px;
  }
  .h-inherit {
    height: inherit;
  }
  .line {
    width: 1px;
    height: 90px;
    margin-right: 30px;
    background: #9f9f9f;
    margin-left: 30px;
  }
  .header {
    margin-top: 30px;
    margin-bottom: 30px;
    background: #e9ebee;
    height: 125px;
    .title {
      font-size: 32px;
      color: #223354;
      font-weight: 500;
    }
  }
  .w-368 {
    width: 368px;
  }
  .h-45 {
    height: 45px;
  }
  .h-100vh {
    height: 100vh;
  }
  .done-tested {
    span {
      font-size: 32px;
      &:first-child {
        font-size: 27px;
        color: #0f94b5;
      }
      &:last-child {
        color: #333333;
        font-weight: 500;
      }
    }
  }
  .btn-bg-yellow {
    background: #fea628;
    border: 1px solid #fea628;
  }
  .time {
    color: #2d99ff;
    font-size: 44px;
    font-weight: 700;
  }
  .testing-for-develop {
    font-size: 32px;
    font-weight: 500;
    color: #223354;
    margin-top: 35px;
  }
  .info-candidate {
    margin-top: 5px;
    span:last-child {
      font-weight: 500;
    }
  }
  .btn-submit-testing {
    color: white;
    &:hover {
      color: white;
      box-shadow: 0 7px 14px #32325d1a, 0 3px 6px #00000014;
      -webkit-transform: translateY(-1px);
      transform: translateY(-1px);
      background-color: #fea628;
      border-color: #fea628;
      border: 1px solid #fea628;
    }
  }
}

.popup-testing-start {
  p {
    margin-bottom: 5px;
    color: #333333;
    font-weight: 400;
    font-size: 14px;
  }
  .MuiDialogContent-root {
    padding: 29px 32px;
    padding-bottom: 0px;
  }

  .MuiDialogActions-root {
    min-width: 361px;
    .btn-testing-cancel {
      color: #8d8d8d;
      &:hover {
        color: #fea628;
        box-shadow: 0 7px 14px #32325d1a, 0 3px 6px #00000014;
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
        background-color: #f7fafc;
        border-color: #f7fafc;
        border: 1px solid #f7fafc;
      }
      &:focus,
      &:active {
        color: #212529;
        background-color: #d2e3ee;
        border-color: #f7fafc;
        border: 1px solid #d2e3ee;
      }
    }
  }
  .btn-done-testing {
    padding: 6px 10px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    text-transform: uppercase;
    &:hover {
      color: #fea628;
      box-shadow: 0 7px 14px #32325d1a, 0 3px 6px #00000014;
      -webkit-transform: translateY(-1px);
      transform: translateY(-1px);
      background-color: #f7fafc;
      border-color: #f7fafc;
      border: 1px solid #f7fafc;
    }
    &:focus,
    &:active {
      color: #212529;
      background-color: #d2e3ee;
      border-color: #f7fafc;
      border: 1px solid #d2e3ee;
    }
  }
  .fa-spinner {
    font-size: 16px;
    margin-left: 6px;
  }
}

.timer-count-down-testing {
  .time {
    word-spacing: -8px;
  }
}

.header-testing-top {
  font-weight: 400;
  font-size: 14px;
  .testing-header-row {
    margin-bottom: 4px;
  }
}

.list-test {
  ul {
    list-style-type: none;
  }
  width: 100%;
}
.img-list-testing {
  margin-bottom: 10px;
  margin-top: 5px;
  height: 200px;
  width: 264px;
  text-align: center;
  background: #e9ebee;
  margin-right: 5px;
  margin-left: 5px;
  justify-content: center;
  display: flex;
  overflow-y: auto;
  img {
    width: 100%;
    object-fit: cover;
    margin: auto;
    display: block;
  }
  &:hover {
    cursor: pointer;
  }
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c4c4c4;
  }
  .list-answer {
    max-height: 190px;
    overflow: auto;
    width: 99.5%;
    margin-right: 8px;
  }
}

.textarea-auto-testing {
  min-width: 600px;
}

.page-not-allow-accept-permission {
  color: #e74c3c !important;
  padding: 30px !important;
  text-align: center;
  font-size: 1rem !important;
  font-weight: 100;
}
