@import 'styles/variable.scss';
.detail-candidate {
  .header-detail {
    margin-top: 25px;
    margin-bottom: 15px;
    justify-content: space-between;
    position: relative;
  }
  .header-left {
    float: left;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }
  .applyCandidteContent {
    margin-left: 10px;
  }
  .text-info-nameStaff {
    font-size: 16px;
    color: #223354;
    font-weight: 500;
    line-height: 19px;
    padding-left: 5px;
  }
  .title-info-Staff {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #223354;
    padding-left: 15px;
  }
  .col-profile-Staff {
    float: left;
    padding-bottom: 15px;
    font-weight: 500;
    width: 40%;
    height: 100%;
    padding-left: 10px;
  }
  .border-content-status {
    border: 1px;
    border-radius: 5px;
    background-color: #e4f5ff;
    border-style: solid;
    border-color: #2d99ff;
    height: 90px;
  }
  .border-content-test {
    margin-left: 10px;
    border: 1px;
    border-radius: 5px;
    background-color: #fff8ee;
    border-style: solid;
    border-color: #fea628;
    height: 90px;
  }
  .border-content-question {
    margin-left: 10px;
    border: 1px;
    border-radius: 5px;
    background-color: #ffdedb;
    border-style: solid;
    border-color: #e74c3c;
    height: 90px;
  }
  .border-content-interview {
    margin-left: 10px;
    border: 1px;
    border-radius: 5px;
    background-color: #dcffeb;
    border-style: solid;
    border-color: #66d997;
    height: 90px;
  }
  .p-content-candidate {
    color: #2d99ff;
    height: 20px;
    justify-content: flex-end;
  }
  .p-content-status {
    font-size: 12px;
    font-weight: 700;
    padding-left: 45px;
    font-family: Roboto;
  }
  .container-date {
    position: relative;
    .p-interview-date {
      position: absolute;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #2d99ff;
      top: -10px;
      left: 45px;
    }
  }

  .p-content-test {
    color: #fea628;
    height: 20px;
    font-family: Roboto;
  }
  .p-content-question {
    color: #e74c3c;
    height: 20px;
    font-family: Roboto;
  }
  .p-content-interview {
    color: #009740;
    height: 20px;
    font-family: Roboto;
  }
  .text-info-email {
    float: left;
    padding-bottom: 15px;
    font-weight: 500;
    width: 35%;
    height: 100%;
    padding-left: 10px;
  }
  .img-icon {
    margin-right: 10px;
    margin-left: 10px;
    width: 16.67px;
    height: 16.67px;
  }
  .header-back {
    margin-bottom: 35px;
  }
  .title-back {
    font-size: 18px;
    font-weight: 700;
  }
  .p-title-detailStatus {
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto;
    padding-top: 10px;
    padding-left: 10px;
  }
  .main-content {
    position: relative;
    .detailStatus {
      background-color: yellow;
      display: none;
      position: absolute;
      top: 100px;
      left: 50px;
      border: solid 1px;
      width: 233px;
      height: 110px;
      background-color: #f3f7fb;
      border-radius: 5px;
      z-index: 1;
    }
    .hoverStatus:hover + .detailStatus {
      display: block;
    }
    .name-detail-candidate {
      font-size: 12px;
      font-weight: 500;
      font-family: Roboto;
      display: flex;
      .p-showStatus-name {
        margin-left: 5px;
      }
      .p-showStatus-watingInterview {
        color: rgba(0, 87, 255, 1);
        margin-left: 5px;
      }
      .p-showStatus-cancelInterview {
        color: rgba(231, 76, 60, 1);
        margin-left: 5px;
      }
      .p-showStatus-doNotReview {
        color: rgba(231, 76, 60, 1);
        margin-left: 5px;
      }
    }
  }

  .button-refuse-interview, .button-hr-evaluate {
    background-color: #e74c3c;
    color: white;
    border-radius: 5px;
    width: 100%;
    min-height: 36px;
    border: none;
    font-weight: 500;
  }
  .button-hr-evaluate {
    background-color: #fea628;
  }
  .button-cancel-onboard {
    background-color: #e74c3c;
    color: white;
    border-radius: 5px;
    width: 100%;
    height: 46px;
    border: none;
    font-weight: 500;
  }
  .button-book-interview {
    background-color: #fea628;
    color: white;
    border-radius: 5px;
    width: 100%;
    min-height: 36px;
    border: none;
    font-weight: 500;
    padding: 0.375rem 0.75rem !important;
  }
  .button-offer {
    background: #fea628;
    color: white;
    border-radius: 5px;
    width: 100%;
    min-height: 36px;
    border: none;
    font-weight: 500;
    padding: 0.375rem 0.75rem !important;
  }
  .accept-offer-btn {
    padding: 1px 6px;
    white-space: normal;
    @media (max-width: 1640px) {
      height: 46px;
    }
  }
  .accept-onboard-btn {
    padding: 1px 6px;
    white-space: normal;
    height: 46px;
  }

  .button-accept-interview {
    justify-content: space-evenly;
    margin-bottom: 10px;
  }
  .styleInfoMui {
    padding-left: 16px;
    padding-top: 16px;
    font-size: 14px;
    color: $color-charcoal;

    .col1InfoLeft {
      padding-left: 10px;
      padding-bottom: 15px;
      font-weight: 500;
      float: left;
      width: 35%;
      height: 100%;
    }
    .col2InfoLeft {
      float: left;
      padding-bottom: 15px;
      font-weight: 500;
      width: 40%;
      height: 100%;
    }
    .styleBackground {
      background-color: $bg-rowTable;
    }
    :global {
      .MuiGrid-item {
        padding-bottom: 15px;
      }
    }
    @media (max-width: 1200px) {
      .col2InfoLeft {
        padding-left: 25px;
        width: 35%;
      }
      .styleBackground {
        background-color: $bg-white;
      }
      :global {
        .MuiGrid-item:nth-child(even) {
          background-color: $bg-rowTable;
        }
      }
    }
  }
}
