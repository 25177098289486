.modalAssign {
  .searchHr {
    :global {
      .MuiInputBase-input {
        padding-left: 0px !important;
        color: #8d8d8d;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .titleListHR {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #223354;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .listHr {
    max-height: 280px;
    overflow-y: scroll;
    margin-right: -1.5rem;
    padding-right: 1.5rem;
    margin-left: -1.5rem;
    padding-left: 1.5rem;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
      border-radius: 30px;
    }
    .rowItem {
      border-bottom: 1px solid #e7e7e7;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 56px;
      &:first-child {
        border-top: 1px solid #e7e7e7;
      }
      .avatar {
        margin-right: 15px;
        width: 35px;
        height: 35px;
      }
      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #223354;
        display: block;
        margin-bottom: 5px;
      }
      .emailAndPosition {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #b8b8b8;
      }
    }
    .isPickedRow {
      background-color: rgba(254, 166, 40, 0.2);
      margin-left: -1.5rem;
      padding-left: 1.5rem;
      margin-right: -1.5rem;
      padding-right: 1.5rem;
    }
    .disabledRow {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
  .buttonModalGroup {
    background-color: white;
    padding: 5px 25px 32px 25px;
    .buttonCancel {
      width: 100%;
      background: #fff8ee;
      border: none;
      border-radius: 5px;
      padding: 6px 16px;
      color: #fea628;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
    }
    .buttonAssign {
      width: 100%;
      background: #fea628;
      border: none;
      border-radius: 5px;
      padding: 6px 16px;
      color: #ffffff;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
.loadingIcon {
  margin-right: 20px;
  height: 28px;
}
.buttonCancelConfirm {
  border: none !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  color: #8d8d8d !important;
  background-color: white !important;
  text-transform: uppercase;
  &:hover {
    color: #8d8d8d !important;
    background-color: #f7fafc !important;
    border: 1px solid #f7fafc;
    box-shadow: 0 7px 14px #32325d1a, 0 3px 6px #00000014;
  }
  &:focus,
  &:active {
    color: #212529;
    background-color: #d2e3ee;
    border-color: #f7fafc;
    border: 1px solid #d2e3ee;
  }
}
.buttonSubmitConfirm {
  border: none !important;
  line-height: 16px !important;
  color: #fea628 !important;
  background-color: white !important;
  text-transform: uppercase;
  padding: 0.375rem 0.75rem !important;
  &:hover {
    color: #fea628 !important;
    background-color: #f7fafc !important;
    border: 1px solid #f7fafc;
    box-shadow: 0 7px 14px #32325d1a, 0 3px 6px #00000014;
  }
  &:focus,
  &:active {
    color: #212529;
    background-color: #d2e3ee;
    border-color: #f7fafc;
    border: 1px solid #d2e3ee;
  }
}
