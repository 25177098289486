.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mb-6 {
  margin-bottom: 6rem !important;
}
.mb-18 {
  margin-bottom: 18px;
}

.h-100 {
  height: 100%;
}
.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.plt-16 {
  padding: 16px 0 0 16px;
}

.plt-24 {
  padding: 24px 0 0 24px;
}

.flex-1 {
  flex: 1;
}
.cursor {
  cursor: pointer;
}
.p-20 {
  padding: 20px;
}
.fw-bold {
  font-weight: bold;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}
.fw-500i {
  font-weight: 500 !important;
}
.btn-transparent {
  background: transparent;
  border: none;
}
.color-yellow {
  color: #fea628;
}
.fs-18 {
  font-size: 18px;
}
.divider {
  border-bottom: 1px solid #e7e7e7;
}
.mr-15 {
  margin-right: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-20 {
  font-size: 20px;
}
