@import 'styles/variable.scss';
.management-question-interview {
  .table-list {
    .stt {
      color: #000000;
    }
    .status {
      color: #223354;
      font-weight: 400;
    }
  }
  .select-skill-test {
    .Mui-disabled {
      opacity: 0.5;
    }
  }
  .text-color {
    color: #000000;
  }
  .header-color {
    color: #223354;
  }
  .header-left {
    padding: 0px !important;
    margin-bottom: 16px;
  }
  .header-infoRecruitment {
    padding: 0 0 20px 0;
    float: left;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .header-infoGeneral {
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
  }
  .status-font {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .radio-text {
    color: #000000;
    .MuiTypography-root {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .header-question {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #000000;
    margin-bottom: -19px;
  }
  .text-content {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }
  .divider {
    border-bottom: 1px solid #e7e7e7;
  }
  .z-5 {
    z-index: 5;
  }
  .box-more-quetion {
    max-height: 707px;
    overflow: auto;
    border: 1px solid #e7e7e7;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #c4c4c4;
    }
  }
  .container-list-question {
    .MuiGrid-root {
      margin-top: 0px !important;
    }
  }
  .filter {
    padding: 0px;
    .button-add-menu-right {
      height: 38px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .info-menu-right-no-image {
      padding-top: 0px;
      padding-bottom: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
    }
  }
  .btn-delete-question:before {
    content: '';
    border-top: 1px solid #e7e7e7;
    position: absolute;
    top: 0;
    right: 17px;
    width: 100%;
    height: 1px;
  }
  .reset {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 22px 32px;
    margin-top: 10px;
    height: 36px;
    background: $color-navActive;
    border-radius: 5px;
    color: $color-white;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    .img-reset {
      margin: '0 10px 0 0';
    }

    &:hover {
      background: $color-navActive;
      color: $color-white;
    }

    &:active {
      background: $color-blue !important;
      color: $color-white !important;
    }
  }
  .Mui-checked {
    color: #fea628 !important;
    & ~ .MuiSwitch-track {
      background: #ffdba9 !important;
    }
  }
  .MuiSwitch-track {
    background: rgba(0, 0, 0, 0.38) !important;
  }
}
.question-interview-popup-delete-text-confirm {
  width: 285px;
  height: 97px;
  .MuiDialogContentText-root {
    color: #333333;
    font-size: 0.875rem;
  }
}
.question-interview-button-color-delete {
  color: #979797;
  margin-right: 27px;
  font-size: 14px;
}
.question-interview-success-save-modal {
  width: 285px;
  height: 77px;
  .MuiDialogContentText-root {
    color: #333333;
    font-size: 0.875rem;
  }
}
.text-font {
  font-size: 14px;
}
.question-interview-active-modal {
  width: 334px;
  height: 96px;
  .MuiDialogContentText-root {
    color: #333333;
  }
}
.p-content {
  padding-left: 10px;
}
.popupConflict__groupBtn {
  margin-top: -18px;
  margin-bottom: 18px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  .groupBtn_btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #fea628;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    letter-spacing: 1.25px;
  }
}
.preview-question-interview {
  .MuiPaper-root {
    min-width: 837px !important;
  }
  .modal-body {
    overflow-x: hidden !important;
    max-height: 821px !important;
  }
  .level {
    color: #fea628;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .title {
    font-size: 16px;
    color: #333333;
  }
  .header-color {
    font-size: 20px;
    color: #223354;
  }
  .text-color {
    color: #000000;
    font-weight: 400;
  }
  .content {
    margin-top: 15px;
    padding: 10px 20px 20px;
  }
  .modal-body {
    padding: 0px !important;
  }

  .header {
    background: #f9f9f9;
    padding: 16px 20px 20px;
    .title {
      font-size: 20px;
      margin: 0;
    }
  }
  .map-question {
    color: #333333;
    font-weight: 400;
    line-height: 22px;
  }
  .mt--5px {
    margin-top: -5px;
  }
  .list-question-by-level {
    li {
      margin: 10px 0px;
      span {
        word-break: break-all;
      }
    }
  }
  .btn-bg-yellow2 {
    padding: 5px 32px !important;
  }
  .btn-bg-yellow1 {
    padding: 0.375rem 0.75rem !important;
  }
}
