.popup-container {
  .MuiPaper-root {
    width: 285px !important;
  }

  .MuiDialogContent-root {
    padding: 20px 8px 20px 24px !important;
  }
  .MuiDialogContentText-root {
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #333333;
  }
}
