.listTotalStatusCandidate {
  height: 405px;
  overflow-y: scroll;
  margin-right: -20px;
  padding-right: 20px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 30px;
  }
}
.listTotalSourceCv {
  overflow-y: scroll;
  margin-right: -20px;
  padding-right: 20px;
  max-height: 376px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 30px;
  }
}

.totalJob {
  display: flex;
  justify-content: flex-end;
  margin-top: -60px;
}

.yearPickerContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: -30px;

  .yearPicker {
    width: 140px;
    height: 35px;
    :global {
      .MuiInputLabel-root {
        color: #bdbdbd;
      }
      .MuiOutlinedInput-root {
        border-radius: 5px;
        cursor: pointer;
      }
      .MuiInputBase-input {
        color: #bdbdbd;
        cursor: pointer;
      }
    }
  }
}
.lineChartContainer {
  margin-bottom: 10px;

  .bodyChart {
    position: relative;
    padding-top: 30px;
    padding-right: 20px;
    height: 347px;
    .labelYAxis {
      position: absolute;
      top: 6px;
      left: 15px;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #223354;
    }
  }
}

.filterButtonGroup {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  .filterBtn {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
    color: #bdbdbd;
    padding: 6px 14px;
    width: 134px;
    margin-left: 10px;
    &:hover {
      cursor: pointer;
      border-color: #66d997;
    }
  }
}

.candidatePassFilter {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  .divisionLabel {
    font-size: 14px;
    top: -3px;
  }
  .candidatePassFilterBtn {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 5px;
    color: #1f1e1e;
    padding: 6px 14px;
    height: 41px;
    margin-left: 0px;
    font-size: 14px;
    width: 300px;
    &:hover {
      cursor: pointer;
      border-color: #66d997;
    }
  }
}
