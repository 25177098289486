.container {
  margin-bottom: 30px;
  margin-top: 18px;
  .columnTop {
    text-align: center;
    padding-right: 2px;
    padding-left: 2px;
    .name {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #8d8d8d;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 5px;
    }
    .value {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #223354;
      margin-top: 3px;
    }
  }
}
