.modalCompare {
  :global {
    .MuiPaper-root {
      min-width: 1120px !important;
    }
    .MuiTabs-indicator {
      background-color: #fea628 !important;
      top: 40px;
    }
    .Mui-selected {
      color: #223354 !important;
    }
    .MuiTabPanel-root {
      padding: 0px;
      padding-top: 24px;
    }
    .MuiTab-root {
      font-weight: 500;
      font-size: 17px;
      line-height: 19px;
    }
  }
  .columnCandidate1 {
    min-width: 530px;
    padding-right: 10px;
  }
  .columnCandidate2 {
    min-width: 530px;
    padding-left: 10px;
  }
  .columnInterviewContainer {
    background: #ffffff;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
    padding-bottom: 20px;
    .infoCandidateContainer {
      padding: 20px;
      .itemContainer {
        height: 42px;
        .titleItem {
          color: #333333;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }
        .contentItem {
          color: #333333;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
