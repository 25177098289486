@import 'styles/variable.scss';

.statistics-candidate-offer {
  .table-container {
    max-height: 650px;
  }

  table {
    width: 130%;
    th {
      color: $color-black;
    }
    td.status-red {
      color: $color-red !important;
    }
    td.status-blue {
      color: $color-blue-download !important;
    }
    td.status-green {
      color: $color-status-blue !important;
    }
    td {
      p {
        margin: 0;
        font-size: 14px;
      }
      .name {
        font-weight: 500;
        color: $color-navActive;
      }
      .email {
        font-size: 14px;
        font-weight: 500;
        color: $color-blue1;
        max-width: 240px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .id-standard {
      color: $color-black !important;
    }
    tr.headTable {
      font-weight: 500;
      color: $color-black;
      background-color: $color-headTable !important;
    }
    tr:nth-child(odd) {
      background-color: $bg-white;
    }
    tr:nth-child(even) {
      background-color: $bg-tableStripped;
    }
  }
  .table-scroll {
    overflow-x: scroll;
    cursor: pointer;
  }
  .scroll-custom {
    border-radius: 0%;
  }
  .scroll-custom::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }
  .scroll-custom::-webkit-scrollbar {
    width: 5px;
  }
  .scroll-custom::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $color-white;
  }
  .scroll-custom::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-navActive;
  }

  .table-content {
    color: $color-titleBody !important;
    font-weight: 400;
    font-size: 14px;
  }

  .stt {
    text-align: center;
    // min-width: 20px;
    width: 20px;
  }

  .field-cell-w100 {
    min-width: 100px;
    width: 100px;
  }

  .field-cell-w90 {
    min-width: 90px;
    width: 90px;
  }

  .field-cell-w150 {
    min-width: 150px;
    width: 150px;
  }
  .text-division {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
